import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { LocalStorageName, RoleEnum, RoleTypeEnum } from '../../enum/role.enum';
import { AppConfigModel } from '../../interfaces/app-config-model';
import { AuthConfigService } from '../preferences-config/auth-config.service';

@Injectable({
    providedIn: 'root'
})
export class OidcAuthService {
  roleEnum = RoleEnum;
  roleTypeEnum = RoleTypeEnum;
  authConfigModel = new AppConfigModel();
  silentRefresh = false;
  /**
   * enum contains local storage varible name
   */
  localStorageName = LocalStorageName;
  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private helperPreferences: AuthConfigService
  ) { }

//redirect url will be window.location.origin
  public configureAndLogin() {
      if (localStorage.getItem(this.localStorageName.redirect_url) === 'fp') {
        //   this.authConfigModel.authConfig.redirectUri = window.location.origin + '/external';
        this.authConfigModel.authConfig.redirectUri = window.location.origin;

      }
      this.oauthService.configure(this.authConfigModel.authConfig);
      this.oauthService.tokenValidationHandler = new NullValidationHandler();
      // this.oauthService.setStorage(localStorage);
      this.oauthService.setStorage(sessionStorage);

      return this.oauthService.loadDiscoveryDocument(this.authConfigModel.DiscoveryDocumentConfig.url).then(results => {
          if (!this.oauthService.hasValidAccessToken()) {
              return this.login();
          }
          return true;
      }, err => {
          return false;
      });
  }

  login() {
      return this.oauthService.tryLoginImplicitFlow().then(result => {
          if (!result) {
              // this.authConfigModel.setLocalStorage();
      this.oauthService.setStorage(sessionStorage);

              this.oauthService.initLoginFlow();
              return false;
          }
          return true;
      }, err => {
          this.handleError(err);
          return false;
      });
  }

  public logout() {
    //   const postRedirectUrl = localStorage.getItem(this.localStorageName.redirect_url) === 'ex' ? this.authConfigModel.externalLoginUrl : this.authConfigModel.internalLoginUrl;
    //   const postRedirectUrl =  this.authConfigModel.internalLoginUrl;
      localStorage.removeItem(this.localStorageName.redirect_url);
    //   this.oauthService.postLogoutRedirectUri = window.location.origin + '/' + postRedirectUrl;
      this.oauthService.postLogoutRedirectUri = window.location.origin;
      this.oauthService.logOut();
    //   localStorage.clear();
      sessionStorage.clear();
  }

  private handleError(err) {
      let errCode = err.params.error_description.split(':')[0];
      switch (errCode) {
      case 'AADB2C90118'://forgot password
          localStorage.setItem(this.localStorageName.redirect_url, 'fp');
          this.authConfigModel.DiscoveryDocumentConfig.url = this.authConfigModel.discoveryDocUrl + environment.forgotPolicyTenantName;
          this.oauthService.loginUrl = this.authConfigModel.authBaseUrl + environment.forgotPolicyTenantName;
          this.oauthService.tokenEndpoint = this.authConfigModel.authBaseUrl + environment.forgotPolicyTenantName;
          this.configureAndLogin();
          break;
      case 'AADB2C90091'://click back btn from forgot password
          this.authConfigModel.DiscoveryDocumentConfig.url = this.authConfigModel.discoveryDocUrl + this.authConfigModel.signInSignUpPolicyName;
          this.oauthService.loginUrl = this.authConfigModel.authBaseUrl + this.authConfigModel.signInSignUpPolicyName;
          this.oauthService.tokenEndpoint = this.authConfigModel.authBaseUrl + this.authConfigModel.signInSignUpPolicyName;
          localStorage.setItem(this.localStorageName.redirect_url, 'fp');
          this.configureAndLogin();
          break;
      default:
          this.authConfigModel.authConfig.redirectUri = window.location.origin;
          this.authConfigModel.DiscoveryDocumentConfig.url = this.authConfigModel.discoveryDocUrl + this.authConfigModel.signInSignUpPolicyName;
          this.oauthService.loginUrl = this.authConfigModel.authBaseUrl + this.authConfigModel.signInSignUpPolicyName;
          this.oauthService.tokenEndpoint = this.authConfigModel.authBaseUrl + this.authConfigModel.signInSignUpPolicyName;
          this.configureAndLogin();
      }
  }

  public get claims() {
      let claims = this.oauthService.getIdentityClaims();
      return claims;
  }
  changePassword(){
    window.location.href = this.authConfigModel.changePasswordUrl;

  }
}
