import { CommonUtil } from 'src/bis/common/utility/Common.util';
import {
  ADD_ADDITIONAL_NOTE, CLEAR_RIDER_INFORMATION, DELETE_ADDITIONAL_NOTE, EDIT_NOTE_ID, FETCH_ACTIVITY_TYPE_LIST_SUCCESS, FETCH_ADDITIONAL_NOTES, FETCH_BOND_DETAILS_SUCCESS, FETCH_BOND_LOGS, FETCH_BOND_REQUESTOR_LIST, FETCH_BOND_STATUS_SUCCESS, FETCH_CLIENT_SEARCH_TYPE_LIST_SUCCESS, FETCH_COUNTRY_LIST_SUCCESS,
  FETCH_IMPORTER_NUMBER_TYPE_LIST_SUCCESS, MERGE_RIDER_WITH_PRINCIPALS, REMOVE_RIDER_INFORMATION, REMOVE_SUB_PRINCIPAL_INFORMATION, RESET_BOND_DETAILS, SET_BOND_ADDITIONAL_NOTES, SET_BOND_CATEGORY_LIST, SET_BOND_DETAILS_FROM_API, SET_BOND_INFORMATION, SET_BOND_SUB_CATEGORY, SET_BOND_TYPE, SET_CHANGE_BOND_AMOUNT_REQUEST, SET_CLIENT, SET_MODE, SET_NOTE_AS_APPROVAL_NOTE, SET_PAPER_BOND_BOND_INFO, SET_PAPER_BOND_PRINCIPAL, SET_PREVIOUS_BOND, SET_PRINCIPAL_INFORMATION, SET_PRINCIPAL_TYPE_ID, SET_REFERENCE_DATE, SET_RIDER_INFORMATION,
  SET_RIDER_TYPE_ID, SET_SIGNATORY_INFORMATION, SET_SIGNATORY_INFORMATION_ID, SET_STB_BOND_TYPE, SET_USER_TYPE, UPDATE_ADDITIONAL_NOTE, VIEW_RIDER_INFORMATION, SET_PAPER_BOND_SURETY_INFO, SET_PAYMENT_DETAILS, SET_PAYMENT_SEARCH_DETAILS
} from '../actions';
import { IBISState } from '../store';

/**
 * Used to manage state based on action dispatched.
 * @param state Used to store state.
 * @param action Used to store action.
 */
export function rootReducer(state: IBISState, action: {type: string, payload: any}): IBISState {
    const { payload } = action;
    switch (action.type) {
        case FETCH_BOND_DETAILS_SUCCESS:
            if (payload.primaryPrincipalActivityDto) {
                const primaryPrincipalActivityDto = {
                    yearsValue:  CommonUtil.addComma(payload.primaryPrincipalActivityDto?.yearsValue),
                    estimatedValue:  CommonUtil.addComma(payload.primaryPrincipalActivityDto?.estimatedValue),
                    yearsDuties:  CommonUtil.addComma(payload.primaryPrincipalActivityDto?.yearsDuties),
                    estimatedDuties:  CommonUtil.addComma(payload.primaryPrincipalActivityDto?.estimatedDuties)
                };
                payload.primaryPrincipalActivityDto = { ...payload.primaryPrincipalActivityDto, ...primaryPrincipalActivityDto };
                payload.principalDtos = payload.principalDtos.sort((a,b) => (a.principalTypeId > b.principalTypeId)?1:-1);
            }
            return { ...state, bondDetails: payload };
        case FETCH_BOND_STATUS_SUCCESS:
            return { ...state, bondDetails: {...state.bondDetails , bondStatus: payload} };
        case RESET_BOND_DETAILS:
            const stateNew = { ...state, bondLogs: [], ...payload };
            return stateNew;
        case SET_CLIENT:
            return {...state, bondDetails: {...state.bondDetails, clientId: payload.encryptedClientId, encryptedClientId: payload.encryptedClientId, clientInformation: payload}};
        case FETCH_BOND_REQUESTOR_LIST:
            return { ...state, bondRequestorList: payload };
        case SET_BOND_TYPE:
            return { ...state, bondType: payload };
        case SET_MODE:
            return { ...state, bondMode: payload };
        case FETCH_COUNTRY_LIST_SUCCESS:
            return { ...state, countryList: payload };
        case FETCH_IMPORTER_NUMBER_TYPE_LIST_SUCCESS:
            return { ...state, importerNumberTypeList: payload };
        case FETCH_ACTIVITY_TYPE_LIST_SUCCESS:
            return { ...state, activityCodeList: payload };
        case FETCH_CLIENT_SEARCH_TYPE_LIST_SUCCESS:
            return { ...state, clientSearchTypeList: payload };
        case SET_PRINCIPAL_INFORMATION:
            if (payload.index === undefined && payload.data) {
                state.bondDetails.principalDtos.push(payload.data);
                return { ...state };
            }
            if (!state.bondDetails.principalDtos.length) {
                state.bondDetails.principalDtos.push(payload.data);
                return { ...state };
            }
            if (state.bondDetails.principalDtos[payload.index]) {
                state.bondDetails.principalDtos[payload.index] = payload.data;
                return { ...state };
            } else {
                state.bondDetails.principalDtos.push(payload.data);
                return {...state };
            }
        case SET_PRINCIPAL_TYPE_ID:
            // if (state.bondDetails.principalDtos.length <= 1) {
            //     return {...state };
            // }
            if (!payload) {
                return;
            }

            if (state.bondDetails.principalDtos[payload.index]) {
                state.bondDetails.principalDtos[payload.index].principalTypeId = payload.data;
            }
            return { ...state };
        case REMOVE_SUB_PRINCIPAL_INFORMATION:
            if (state.bondDetails.principalDtos.length <= 1) {
                return {...state };
            }
            if (!payload) {
                return;
            }

            if (state.bondDetails.principalDtos[payload]) {
                state.bondDetails.principalDtos = state.bondDetails.principalDtos.filter((data, index) => index !== payload);
            }
            return { ...state };
        case SET_BOND_INFORMATION:
            return {...state, bondDetails: {...payload}};
        case SET_PREVIOUS_BOND:
            return {...state, bondDetails: {...state.bondDetails, previousBondDto: payload}};
        case SET_STB_BOND_TYPE:
            return {...state, stbBondTypeList: payload};
        case SET_BOND_SUB_CATEGORY:
            return {...state, bondDetails: {...state.bondDetails, bondDesignationCodeId: payload}};
        case SET_SIGNATORY_INFORMATION:
            return {...state, bondSignatureList: payload};
        case SET_SIGNATORY_INFORMATION_ID:
            return {...state, bondDetails: {...state.bondDetails, signatureId: payload}};
        case SET_BOND_DETAILS_FROM_API:
            return {...state, bondDetailsFromApi: payload};
        case ADD_ADDITIONAL_NOTE:
            state.bondDetails.bondAdditionalNotes = [payload, ...state.bondDetails.bondAdditionalNotes];
            return { ...state };
        case DELETE_ADDITIONAL_NOTE:
            if (!payload || !state.bondDetails.bondAdditionalNotes.length) {
                return {...state};
            }
            // tslint:disable-next-line: max-line-length
            state.bondDetails.bondAdditionalNotes = state.bondDetails.bondAdditionalNotes.filter(details => details.bondAdditionalNotesId !== payload);
            return { ...state };
        case UPDATE_ADDITIONAL_NOTE:
            // tslint:disable-next-line: max-line-length
            const targetAdditionalNote = state.bondDetails.bondAdditionalNotes.findIndex(noteDetails => noteDetails.bondAdditionalNotesId === payload.bondAdditionalNotesId);
            // tslint:disable-next-line: max-line-length
            const otherNotes = state.bondDetails.bondAdditionalNotes.filter(noteDetails => noteDetails.bondAdditionalNotesId !== payload.bondAdditionalNotesId);
            if (!targetAdditionalNote && !otherNotes.length) {
                return {...state};
            }

            state.bondDetails.bondAdditionalNotes = [payload, ...otherNotes];
            return { ...state };
        case FETCH_ADDITIONAL_NOTES:
            return {...state, bondDetails: {...state.bondDetails, bondAdditionalNotes: payload}};
        case SET_NOTE_AS_APPROVAL_NOTE:
            return { ...state, bondDetails: { ...state.bondDetails, isNoteApproval: payload }};
        case EDIT_NOTE_ID:
            return { ...state, bondDetails: { ...state.bondDetails, editedNoteId: payload }};
        case SET_RIDER_INFORMATION:
            if (payload.index === undefined && payload.data) {
                state.bondRiderDetails.push(payload.data);
                return { ...state };
            }
            if (!state.bondRiderDetails.length) {
                state.bondRiderDetails.push(payload.data);
                return { ...state };
            }
            if (state.bondRiderDetails[payload.index]) {
                state.bondRiderDetails[payload.index] = payload.data;
                return { ...state };
            } else {
                state.bondRiderDetails.push(payload.data);
                return { ...state };
            }
        case SET_RIDER_TYPE_ID:
            // if (state.bondDetails.principalDtos.length <= 1) {
            //     return {...state };
            // }
            if (!payload) {
                return;
            }

            if (state.bondRiderDetails[payload.index]) {
                state.bondRiderDetails[payload.index].principalTypeId = payload.data;
            }
            return { ...state };
        case REMOVE_RIDER_INFORMATION:
            // if (state.bondRiderDetails.length <= 1) {
            //     return { ...state };
            // }
            // if (!payload) {
            //     return;
            // }

            if (state.bondRiderDetails[payload]) {
                state.bondRiderDetails = state.bondRiderDetails.filter((data, index) => index !== payload);
            }
            return { ...state };
        case CLEAR_RIDER_INFORMATION:
            return { ...state, bondRiderDetails: [] };
        case VIEW_RIDER_INFORMATION:
            return { ...state, bondRiderDetails: payload };
        case MERGE_RIDER_WITH_PRINCIPALS:
            if (payload) {
                payload.map(ele => state.bondDetails.principalDtos.push(ele));
            }

            return { ...state };
        case SET_PAPER_BOND_PRINCIPAL:
            return { ...state, bondDetails: { ...state.bondDetails, paperBondPrincipalInfo: payload}};
        case SET_BOND_ADDITIONAL_NOTES:
            return { ...state, bondDetails: { ...state.bondDetails, bondAdditionalNotes: payload}};
        case SET_USER_TYPE:
            return { ...state, userType: payload };
        case SET_CHANGE_BOND_AMOUNT_REQUEST:
            return { ...state, bondDetails: {...state.bondDetails, changeBondAmountResponseDto: payload }};
        case SET_PAPER_BOND_BOND_INFO:
            return { ...state, bondDetails: {...state.bondDetails, paperBondBondInformation: payload} };
        case FETCH_BOND_LOGS:
            return { ...state, bondLogs: payload };
        case SET_REFERENCE_DATE:
            state.bondDetails.referenceDate = payload;
            return {...state};
        case SET_BOND_CATEGORY_LIST:
            return {...state, bondCategoryList: payload };
        case SET_PAPER_BOND_SURETY_INFO:
            return { ...state, bondDetails: {...state.bondDetails, paperBondSuretyInformation: payload} };
        case SET_PAYMENT_DETAILS:
            return { ...state, epaymentDetailsList: {...state.epaymentDetailsList,epaymentDetailsInformation:payload} };
        case SET_PAYMENT_SEARCH_DETAILS:
            return { ...state, epaymentSearchDetailsList: {...state.epaymentSearchDetailsList,epaymentSearchDetailsInformation:payload} };
           
                default:
            return state;
    }
}
