import { Component, Input } from '@angular/core';

@Component({
    selector: 'bis-form-error',
    templateUrl: './form-error.component.html',
    styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent {

    /**
     * Used to type of error.
     */
    @Input() type = 'error';

    /**
     * Used to store message.
     */
    @Input() message: string;

    /**
     * Used to store l10n message key;
     */
    @Input() l10nMessage: string;
}
