import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wrapPositiveAmount'
})
export class WrapPositiveAmountPipe implements PipeTransform {

    transform(value, wrapper: 'parenthesis' | 'brackets' = 'parenthesis'): any {
        value = parseFloat(value).toFixed(2);
        if (value < 0) {
            switch (wrapper) {
                case 'parenthesis':
                        return `(${Math.abs(value).toFixed(2)})`;
                case 'brackets':
                    return `[${Math.abs(value)}]`;
            }
        }
        return value;
    }

}
