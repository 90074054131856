import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BisModule } from './bis/bis.module';
import { environment } from './environments/environment';


if (environment.production) {
    enableProdMode();
    console.log = function(){};
    console.error = function(){};
    console.warn = function(){};
}

platformBrowserDynamic().bootstrapModule(BisModule)
    .catch(err => console.error(err));
