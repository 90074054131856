import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/bis/shared/services/loader/loader.service';

@Component({
    selector: 'bis-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  isShow = false;
  constructor(private loaderService: LoaderService) { }

  ngOnInit(): void {
      this.loaderService.isShow.subscribe((data: any) => {
          this.isShow = data.isShow;
      });
  }

}
