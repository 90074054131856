
<ng-container *ngIf="labelField">
    <div [formGroup]="form">
        <p-dropdown
            [ngClass]="getClass()"
            class="mr-sm-2"
            [options]="options" 
            [placeholder]="l10nPlaceholder | l10n"
            optionLabel="{{labelField}}"
            formControlName='select'
            [ngClass]="{'is-invalid': form.get('select').touched && form.get('select').invalid}">
        </p-dropdown>
        <fa-icon class="api-loading dropdown-load-icon" *ngIf="showLoader" [icon]="icon" [spin]="true"></fa-icon>
        <i  *ngIf="IsErrorOccured"
            class="fa fa-exclamation-triangle api-error dropdown-load-icon"
            [pTooltip]="'Refresh'"
            (click)="refresh()"
            aria-hidden="true">
        </i>
    </div>
</ng-container>