import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'TextWrap'
})
export class TextWrapPipe implements PipeTransform {
    transform(value: string, maxlength: number = 50) {
        if (!value) {
            return '';
        }

        if (value.length <= maxlength) {
            return value;
        }
        return `${value.slice(0, maxlength)}....`;
    }
}
