import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RoleEnum } from '../../enum/role.enum';
import { ICancleBondDataInterface } from '../../interfaces/cancle-bond-data.interface';
import { HttpService } from '../http/providers/http.service';
import { AuthConfigService } from '../preferences-config/auth-config.service';

@Injectable({
    providedIn: 'root'
})
export class LoginConfigurationService {
    private role = RoleEnum;
    wrongUserDetails: boolean;

    /**
     * Used while navigating from bond page to cancel bond page
     */
    public cancelBondData:ICancleBondDataInterface;

    constructor(
        private helperService: HttpService,
        private helperPreferences: AuthConfigService,
        private http: HttpClient,
    ) { }

    /**
     * Used to call api for get user userdetails and preferences
     */
    public getUserDetailsAndPreferences() {
        return this.getUserDetails();
    }

    /**
     * Used to call getuserdetails api and store role into localstorage
     */
    getUserDetails(force = false) {
        const promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (this.helperPreferences.rolesAndPreferences && !force) {
                resolve(true);
                return;
            }
            this.getDetails('Account/getuserdetails').subscribe(
                {
                    next: (result: any) => {
                        if (result) {
                            this.helperPreferences.checkQuestaWebStatus();

                            this.helperPreferences.loggedInUserDetails = result;
                            for (let j = 0; j < Object.getOwnPropertyNames(this.role).length; j++) {
                                if (parseInt(this.role[Object.getOwnPropertyNames(this.role)[j]]) === result.userCategoryId) {
                                    this.helperPreferences.userType = Object.getOwnPropertyNames(this.role)[j];
                                }
                            }
                            if (environment.enableRolesAndPreferences) {
                                this.helperPreferences.rolesAndPreferences = result.userPreferences;
                                this.helperPreferences.rolesAndPreferenceLoaded.next(true);
                                resolve(true);
                                return;
                            }
                            this.getUserPreferences(result.encryptedUserId).then(success => {
                                resolve(true);
                            });
                            return;
                        }
                        this.wrongUserDetails = true;

                        reject(true);
                    }, error: error => {
                        this.wrongUserDetails = true;

                        reject(true);
                    }
                });
        });
        return promise;
    }

    /**
     * Used to get user preferences and store preferences into localstorage
     * @param userid user id to get preferences
     */
    private getUserPreferences(userId: string) {
        const promise: Promise<boolean> = new Promise((resolve, reject) => {
            this.getDetails(`Preference/getuserpreferences/${userId}`).subscribe(
                {
                    next: (result: any) => {
                        this.helperPreferences.rolesAndPreferences = result;

                        resolve(true);
                    }, error: error => {
                        reject(true);
                    }
                });
        });
        return promise;
    }

    /**
     * Used to call http get method
     */
    private getDetails(url: string) {
        return this.helperService.get(url).pipe(map(
            (result: any) => {
                return result;
            })
        );
    }
    removeCache() {
        const url = `Epay/clearsession`;
        return  this.http.get<any>(url).pipe(
            map(res => {
                return res;
            })
        );
    }
}
