<div class="fb-item">
    <div class="first-section-wrapper">
        <div class="gravatar">
            <ngx-skeleton-loader
              appearance="circle"
              [theme]="{ width: '80px', height: '80px' }">
            </ngx-skeleton-loader>
          </div>
          <div class="gravatar-title">
            <div>
                <ngx-skeleton-loader
                    [theme]="{
                    width: '200px',
                    'border-radius': '0',
                    height: '15px',
                    'margin-bottom': '10px',
                    'background-color': '#A9A9A9'
                    }">
                </ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                [theme]="{ width: '170px', 'border-radius': '0', height: '15px', 'background-color': '#A9A9A9' }"
              ></ngx-skeleton-loader>
            </div>
          </div>
        </div>
        <div class="second-section-wrapper">
          <div class="wrapper">
            <ngx-skeleton-loader
              [theme]="{
                width: '80%',
                'border-radius': '0',
                height: '15px',
                'margin-bottom': '10px',
                'background-color': '#A9A9A9'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="wrapper">
            <ngx-skeleton-loader
              [theme]="{
                width: '90%',
                'border-radius': '0',
                height: '15px',
                'margin-bottom': '10px',
                'background-color': '#A9A9A9'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="wrapper">
            <ngx-skeleton-loader
              [theme]="{
                width: '60%',
                'border-radius': '0',
                height: '15px',
                'margin-bottom': '10px',
                'background-color': '#A9A9A9'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>