import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'bis-external-login',
    templateUrl: './external-login.component.html'
})
export class ExternalLoginComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
