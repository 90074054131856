import { UntypedFormGroup } from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { startCase } from 'lodash';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import * as numeral from 'numeral';
import { IColumn } from 'src/bis/shared/interfaces/column.interface';
import { DATE_TIME_FORMAT } from './constants';

export class CommonUtil {
    /**
     * Used to store spinner icon.
     */
    static spinnerIcon = faSpinner;

    /**
     * Used to return control status.
     * @param form used to store target form.
     * @param controlName Store control name.
     */
    static checkTouchedAndInvalid(form: UntypedFormGroup, controlName: string, dirty = false): boolean {
        const controlDetails = form.get(controlName);
        if (!controlDetails) {
            return false;
        }
        if (dirty) {
            return controlDetails.touched && controlDetails.invalid && controlDetails.dirty;
        }
        return controlDetails.touched && controlDetails.invalid;
    }

    /**
     * Used to check for error in control.
     * @param form used to store target form.
     * @param controlName Store control name.
     * @param errorKey Store error key.
     */
    static checkForErrors(form: UntypedFormGroup, controlName: string, errorKey: string, showOnDirtyOnly = false) {
        const controlDetails = form.get(controlName);

        if (!controlDetails) {
            return false;
        }
        if (!controlDetails.errors) {
            return false;
        }

        if (showOnDirtyOnly) {
            if (controlDetails.dirty) {
                return controlDetails.errors[errorKey];
            }
        } else {
            return controlDetails.errors[errorKey];
        }
    }

    /**
     * Used to return error message
     * @param messageList Used to store message List.
     */
    static getToolTipMessage(messageList: { target: string, value: boolean, message: string }[]) {
        const targetMessage = messageList.find(details => details.value);
        if (!targetMessage) {
            return '';
        }
        return targetMessage.message || '';
    }

    /**
     * Used extract column details.
     * @param columnDetails Store details
     */
    static extractColoumnList(columnDetails, ColNameList: string[] = [], action: 'skip' | 'take' = 'take') {
        const colList = Object.keys(columnDetails)
            .map(key => ({ field: key, header: startCase(key), hidden: false }));
        if (ColNameList.length && action === 'skip') {
            return this.hideColumn(colList, ColNameList);
        } else if (ColNameList.length && action === 'take') {
            return colList.map(res => (
                
                { 
                    ...res, hidden: !ColNameList.some(column => column === res.field) }
            ));
        }
        
        return colList;
    }

    /**
     * Used to hide some column
     * @param columnList Store original column list.
     * @param excludeList Store list of cloumn want to hide.
     */
    static hideColumn(columnList: IColumn[], excludeList: string[]) {

        return columnList.map(res => (
            { ...res, hidden: excludeList.includes(res.field)  }
        ));
    }

    static renameColumnNames(columnList: any[], modifierList: { old: string, new }[]) {
        if (!columnList || !columnList.length || !modifierList || !modifierList.length) {
            return;
        }
        return columnList.map(res => {
            const column = modifierList.find(columnDetails => columnDetails.old === res.field);
            if (column) {
                return { ...res, header: column.new };
            } else {
                return res;
            }
        });
    }

    /**
     * Used to convert UTC time to  browser specific time.
     * @param Used to store source time.
     * @param format Used to store target format.
     * TODO : Use TIMEZONE_FORMAT for timezone format.
     */
    static convertDateTimeIntoLocal(date, format = `${DATE_TIME_FORMAT}`) {
        if (!date) {
            return moment().format(format);
        }
        const formattedDate = moment(new Date(date)).format(format)
        // TODO: this will require to check for local date
        // console.log('formatted date', date, formattedDate);
        return formattedDate;
    }

    /**
     * Used to convert date time to date.
     * @param format Used to store target format.
     */
    static convertDateTimeIntoDate(date, format = `${DATE_TIME_FORMAT}`) {
        if (!date) {
            return moment(new Date().toISOString().substring(0, 10)).format(format);
        }
        const formattedDate = moment(date.substr(0, 10)).format(format);

        return formattedDate;
    }

        /**
     * Used to convert date time to date.
     * @param format Used to store target format.
     */
         static convertDateTimeIntoDateFormat(date, format = `MM/DD/YYYY`) {
          if (!date) {
              return moment(new Date().toISOString().substring(0, 10)).format(format);
          }
          const formattedDate = moment(date.substr(0, 10)).format(format);

          return formattedDate;
      }

    /**
     * Used to return moment object
     * @param date Used to store date.
     * @param format used to store format.
     */
    static convertDateInToMoment(date, format): Date | string {
        if (!date) {
            return '';
        }
        if (!format) {
            return moment(date).toDate();
        }
        return moment(date, format).toDate();
    }

    /**
     * Get Browser specific time zone.
     */
    static getLocalTimeZone() {
        return momentTz.tz("EST");
    }

    /**
     * Used to add comma in amount.
     * @param value Used to store value.
     */
    static addComma(value: number) {
        if (!value) {
            return '';
        }
        return numeral(value).format('0,0');
    }

    /**
     * Used to remove comma
     * @param value Used to store value.
     */
    static removeComma(value) {
        return numeral(value).value();
    }

    /**
     * Used to add comma with $ in amount.
     * @param value Used to store value.
     */
    static formatAmount(value: number) {
        if (!value) {
            return '';
        }
        return `$${numeral(value).format('0,0')}`;
    }
}
