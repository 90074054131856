import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'bis-common-print',
    templateUrl: './print.component.html'
})
export class PrintComponent {
    constructor() { }

    /**
     * Used to store download label
     */
    @Input() label: string;

    /**
     * Used to store status of multiple download.
     * by default multi is false.
     */
    @Input() invoiceType: any;

    /**
     * Used to emit click event
     */
    @Output() buttonClick = new EventEmitter<{data: any, state: boolean}>();

    /**
     * Used to store past record details.
     */
    @Input() RecordDetails: any;

    /**
     * Used to store flag for print.
     */
    @Input() print = false;

    /**
     * Used to store invoice id.
     */
    @Input() invoiceIds;

    /**
     * Used to store print progress status.
     */
    isPrintInProgress = false;

    /**
     * Used to store icon.
     */
    icon = faSpinner;

    /**
     * Used to download invoice.
     */
    onPrint() {
    //     if (!this.print) {
    //         this.buttonClick.emit({data: this.RecordDetails, state: true});
    //     } else {
    //         let url;
    //         this.isPrintInProgress = true;
    //         if (this.invoiceType === InvoiceTypes.CARGO) {
    //             url  = `downloadinvoice?invoiceIds=${this.invoiceIds.join(',')}`;
    //         } else {
    //             url  = `downloadbisinvoice?invoiceIds=${this.invoiceIds.join(',')}`;
    //         }

    //         this.accountingService.downloadInvoicebyIds(url).pipe(
    //             map(res => {
    //                 return res.map(file => {
    //                     const fileDetails = {
    //                         name: file.fileName,
    //                         file: new Blob([
    //                             new Uint8Array(atob(file.fileBytes).split('').map(char => char.charCodeAt(0)))
    //                         ], {type: 'application/pdf'})
    //                     };
    //                     return fileDetails;
    //                 });
    //             })
    //         ).subscribe(
    //             res => {
    //                 this.isPrintInProgress = false;
    //                 res.map(fileDetails => {
    //                     const blobUrl = URL.createObjectURL(fileDetails.file);
    //                     const iframe = document.createElement('iframe');
    //                     iframe.style.display = 'none';
    //                     iframe.src = blobUrl;
    //                     document.body.appendChild(iframe);
    //                     iframe.contentWindow.print();
    //                 });
    //             },
    //             error: error => {
    //                 this.isPrintInProgress = false;
    //             }
    //         );
    //     }
    }
}
