import { Injectable } from '@angular/core';
import { startCase } from 'lodash';
import { IAPIWrapper } from '../../../shared/interfaces/apiwrapper.interface';
import { IColumn } from '../../../shared/interfaces/column.interface';
import { ToasterService } from '../../../shared/services/errorhandler/toaster.service';
/**
 * Used to add some utility methods.
 */
@Injectable({providedIn: 'root'})
export class ReportsUtility {
    constructor(private toaster: ToasterService) { }
    /**
     * Used extract column details.
     * @param columnDetails Store details
     */
    static extractColoumnList(columnDetails, hiddenColList: string[] = []) {
        const colList = Object.keys(columnDetails)
            .filter(res => !res.toLocaleLowerCase().includes('error'))
            .map(key => ({field: key, header: startCase(key), hidden: false} ));
        if (hiddenColList.length) {
            return this.hideColumn(colList, hiddenColList);
        } else {
            return colList;
        }
    }

    /**
     * Used to add new Item in breadcrumb list.
     * @param breadCrumbList Store existing list of breadcrumb itesm.
     * @param label Used to store breadcrumb label.
     * @param route Used to store route for breadcrumb.
     */
    static addBreadCrumbItem(breadCrumbList: any[], label: string, route = '') {
        const item = { label: label.toUpperCase(), route: ''};
        breadCrumbList.push(item);
        return breadCrumbList;
    }

    /**
     * Used to hide some column
     * @param columnList Store original column list.
     * @param excludeList Store list of cloumn want to hide.
     */
    static hideColumn(columnList: IColumn[], excludeList: string[]) {
        return columnList.map(res => (
            {...res, hidden: excludeList.some(column => column === res.field)}
        ));
    }

    static renameColumnNames(columnList: any[], modifierList: {old: string, new}[]) {
        if (!columnList || !columnList.length || !modifierList || !modifierList.length) {
            return;
        }
        return columnList.map(res => {
            const column = modifierList.find(columnDetails => columnDetails.old === res.field);
            if (column) {
                return { ... res, header: column.new};
            } else {
                return res;
            }
        });
    }

    /**
     * Used to handle api toasters.
     * @param response Store api response.
     * @param showSuccess Store show success message staus .
     */
    handleAPIErrorSuccess(response: IAPIWrapper<any>, showSuccess = true) {
        if (!response.IsSuccess) {
            this.toaster.error('Error', response.ReturnMessage);
            if(response.Data){
                return response.Data;
            }
            return {};
        }
        if (showSuccess) {
            this.toaster.success('Success', response.ReturnMessage);
        }
        return response.Data;
    }

	/**
	 * format dates in specific format
	 * @param date date to be formatted
	 */
     static formatDate(date) {
		let d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;

		return [year, month, day].join('-');
	}
    
}
