import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { BusyPipe } from '../shared/pipe/busy.pipe';
import { L10nPipe } from '../shared/pipe/l10n.pipe';
import { BusyService } from '../shared/services/busy.service';
import { GoogleGeocodeService } from '../shared/services/google-geocoder/google-geocoder.service';
import { L10nService } from '../shared/services/l10n/l10n.service';
import { DotLoaderComponent } from './component/dot-loader/dot-loader.component';
import { EmailComponent } from './component/email/email.component';
import { FormErrorComponent } from './component/form-error/form-error.component';
import { PrintComponent } from './component/print/print.component';
import { SelectComponent } from './component/select/select.component';
import { ParagraphSkeletonComponent } from './component/skeleton-loaders/paragraph/paragraph.component';
import { ProfileSkeletonComponent } from './component/skeleton-loaders/profile/profile.component';
import { TableSkeletonComponent } from './component/skeleton-loaders/table/table.component';
import { UnauthorizedPageComponent } from './component/unauthorized-page/unauthorized-page.component';
import { AllTextWithSlashDirective } from './directives/all-text-with-slash.directive';
import { AllTextDirective } from './directives/all-text.directive';
import { AutofocusDirective } from './directives/auto-focus-directive';
import { BondAmountDirective } from './directives/bond-amount.directive';
import { DecimalAmountDirective } from './directives/decimal-amount.directive';
import { IsControlRequiredDirective } from './directives/is-control-required.directive';
import { KeyPreventDirective } from './directives/key-prevent';
import { NoWhiteSpaceDirective } from './directives/no-whitespace.directive';
import { PatternDirective } from './directives/pattern.directive';
import { TabIndexDirectives } from './directives/tab-index.directive';
import { ToolTipErrorHandlerDirective } from './directives/tooltip-error.directive';
import { ExternalLoginComponent } from './external-login/external-login.component';
import { HeaderComponent } from './header/header.component';
import { LoaderComponent } from './loader/loader.component';
import { ScrollingTextComponent } from './scrolling-text/scrolling-text.component';
import { NegativeAmountPipe } from './pipe/negative-amount.pipe';
import { TextWrapPipe } from './pipe/text-wrap.pipe';
import { AmountWrapperPipe } from './pipe/wrap-amount.pipe';
import { WrapPositiveAmountPipe } from './pipe/wrap-positive-amount.pipe';
import { ToastComponent } from './toast/toast.component';
import { TableListComponent } from './component/table-list/table-list.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ToastComponent,
    LoaderComponent,
    ScrollingTextComponent,
    L10nPipe,
    FormErrorComponent,
    SelectComponent,
    ParagraphSkeletonComponent,
    ProfileSkeletonComponent,
    TableSkeletonComponent,
    ExternalLoginComponent,
    IsControlRequiredDirective,
    BusyPipe,
    DotLoaderComponent,
    ToolTipErrorHandlerDirective,
    AmountWrapperPipe,
    DecimalAmountDirective,
    NoWhiteSpaceDirective,
    KeyPreventDirective,
    TextWrapPipe,
    PatternDirective,
    EmailComponent,
    PrintComponent,
    NegativeAmountPipe,
    AllTextDirective,
    AllTextWithSlashDirective,
    TabIndexDirectives,
    AutofocusDirective,
    WrapPositiveAmountPipe,
    UnauthorizedPageComponent,
    BondAmountDirective,
    TableListComponent
  ],
  exports: [
    HeaderComponent,
    ToastComponent,
    LoaderComponent,
    ScrollingTextComponent,
    L10nPipe,
    FormErrorComponent,
    SelectComponent,
    ParagraphSkeletonComponent,
    ProfileSkeletonComponent,
    TableSkeletonComponent,
    IsControlRequiredDirective,
    DotLoaderComponent,
    ToolTipErrorHandlerDirective,
    AmountWrapperPipe,
    DecimalAmountDirective,
    NoWhiteSpaceDirective,
    KeyPreventDirective,
    TextWrapPipe,
    PatternDirective,
    DropdownModule,
    CalendarModule,
    TooltipModule,
    FontAwesomeModule,
    BreadcrumbModule,
    TableModule,
    EmailComponent,
    ConfirmDialogModule,
    PrintComponent,
    NegativeAmountPipe,
    TabMenuModule,
    DialogModule,
    RadioButtonModule,
    AllTextDirective,
    AllTextWithSlashDirective,
    TabIndexDirectives,
    AutofocusDirective,
    WrapPositiveAmountPipe,
    BondAmountDirective,
    TableListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ToastModule,
    ProgressSpinnerModule,
    OverlayPanelModule,
    RouterModule,
    DropdownModule,
    FontAwesomeModule,
    NgxSkeletonLoaderModule,
    TableModule,
    ReactiveFormsModule,
    CalendarModule,
    TooltipModule,
    FontAwesomeModule,
    BreadcrumbModule,
    TableModule,
    ConfirmDialogModule,
    TabMenuModule,
    DialogModule,
    RadioButtonModule,
    FormsModule
  ],
  providers: [
    MessageService,
    L10nService,
    BusyService,
    GoogleGeocodeService
  ]
})
export class CommonCoreModule {
}
