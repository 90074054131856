import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
  public isShow = new Subject<any>();

  public apiLog: {name: string, time: string}[] = [];

  public apiLogEmitter = new Subject<{name: string, time: string}[]>();
  constructor() { }

  showLoader(): void {
      this.isShow.next({ isShow: true });
  }

  hideLoader(): void {
      this.isShow.next({ isShow: false });
  }

  addLog(logDetails: {name: string, time: string}) {
      this.apiLog.push(logDetails);
      this.apiLogEmitter.next(this.apiLog);
  }

  clearLog() {
      this.apiLog = [];
      this.apiLogEmitter.next([]);
  }

}
