import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import * as numeral from 'numeral';
import { BondTypes } from 'src/bis/modules/bond-management/v2/enum/bond-types.enum';

@Directive({
    selector: '[bisBondAmount]'
})
export class BondAmountDirective {

    constructor(
        private el: ElementRef
    ) { }
    @Input() bisBondAmount: {type: BondTypes, code: number};
    @HostListener('focusout')
    onFocusOut() {
        if (!numeral(this.el.nativeElement.value).value()) {
            this.el.nativeElement.value = '';
            return;
        }
        if(this.bisBondAmount.code !== 1){// If activity code is not importer or broker
            this.el.nativeElement.value = numeral(this.el.nativeElement.value).format('0,0');
            return;
        }
        let initalValue = Number(this.el.nativeElement.value.toString().split(',').join(''));
        if (this.bisBondAmount.type === BondTypes.CTB_ID) {
            initalValue = this.minimumBondAmount(initalValue);
        }
        if (this.bisBondAmount.type === BondTypes.STB_ID) {

            initalValue = Math.ceil(Number(initalValue.toString().split(',').join('')));
        }
        const originalAmount = numeral(initalValue).format('0,0');
        this.el.nativeElement.value = originalAmount;
    }
    @HostListener('input', ['$event']) onInputChange(event) {
        let initalValue = this.el.nativeElement.value;
        this.el.nativeElement.value = initalValue.replace(/[^0-9,]*/g, '').substring(0, 14);
        if (initalValue !== this.el.nativeElement.value) {
            event.stopPropagation();
            return;
        }
    }

    minimumBondAmount(bondAmt) {
        // if (bondAmt <= 50000) {
        //     return numeral(50000).format('0,0');
        // } else
         if (bondAmt >= 50000 && bondAmt <= 99999) {
            return numeral(50000 + (Math.floor(((bondAmt % 50000) - 1) / 10000) + 1) * 10000).format('0,0');
        } else if (bondAmt >= 100000) {
            return numeral(((Math.floor((bondAmt - 1) / 100000)) + 1) * 100000).format('0,0');
        }
        return bondAmt;
    }

}
