/**
 * Used to map the user category id
 */
export enum RoleEnum {
    epic = 1, // Epic user
    chb = 2 // Chb user
}

/**
 * Used to map and access the types of user
 */
export enum RoleTypeEnum {
    epic = 'epic', // Epic user
    chb = 'chb' // Chb user
}

export enum LocalStorageName {
    userType = 'user_role',
    userPreferences = 'u_preferences',
    redirect_url = 'redirect_url'
}

/**
 * Used to store Role Id enum.
 */
export enum RoleIdEnum {
    EPIC_SUPER_ADMIN = 1,
    EPIC_ADMIN,
    MASTER_BROKERS_HQ,//Super Master Broker
    MASTER_BROKER,
    BROKER,
    ACCOUNTING_EPIC,
    ACCOUNTING_Client,
    MASTER_ACCOUNTING_ROLE,
    VIEW_ONLY,
    AGENCY,
    ABI,
    SURETY_SUPER_ADMIN
}
