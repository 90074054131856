import { IClientType } from 'src/bis/modules/administration/modules/client-management/interfaces/IClientType';
import { IImporterNumberType } from 'src/bis/modules/bond-management/interfaces/IImporterNumberType';
import { IActivityCode } from 'src/bis/modules/bond-management/v2/interface/activity-code.interface';
import { IAdditionalNote } from 'src/bis/modules/bond-management/v2/interface/additionalNote.interface';
import { IChangeBondAmount } from 'src/bis/modules/bond-management/v2/interface/change-bond-amount.interface';
import { IClient } from 'src/bis/modules/bond-management/v2/interface/client.interface';
import { ICountryList } from 'src/bis/modules/bond-management/v2/interface/country-list';
import { IPaperBondBondInfo } from 'src/bis/modules/bond-management/v2/interface/paper-bond-bond-info.interface';
import { IPaperBondSuretyInfo } from 'src/bis/modules/bond-management/v2/interface/paper-bond-surety-info.interface';
import { IPrincipalInformation } from 'src/bis/modules/bond-management/v2/interface/principal-information.interface';
import { IRequestor } from 'src/bis/modules/bond-management/v2/interface/requestor.interface';
import {
  ADD_ADDITIONAL_NOTE, CLEAR_RIDER_INFORMATION, DELETE_ADDITIONAL_NOTE, EDIT_NOTE_ID, FETCH_ACTIVITY_TYPE_LIST_SUCCESS, FETCH_ADDITIONAL_NOTES, FETCH_BOND_DETAILS_SUCCESS, FETCH_BOND_LOGS, FETCH_BOND_REQUESTOR_LIST, FETCH_BOND_STATUS_SUCCESS, FETCH_CLIENT_SEARCH_TYPE_LIST_SUCCESS, FETCH_COUNTRY_LIST_SUCCESS,
  FETCH_IMPORTER_NUMBER_TYPE_LIST_SUCCESS, MERGE_RIDER_WITH_PRINCIPALS, REMOVE_RIDER_INFORMATION, REMOVE_SUB_PRINCIPAL_INFORMATION, RESET_BOND_DETAILS, SET_BOND_ADDITIONAL_NOTES, SET_BOND_CATEGORY_LIST, SET_BOND_DETAILS_FROM_API, SET_BOND_INFORMATION, SET_BOND_SUB_CATEGORY, SET_BOND_TYPE, SET_CHANGE_BOND_AMOUNT_REQUEST, SET_CLIENT, SET_MODE, SET_NOTE_AS_APPROVAL_NOTE, SET_PAPER_BOND_BOND_INFO, SET_PAPER_BOND_PRINCIPAL, SET_PAPER_BOND_SURETY_INFO, SET_PAYMENT_DETAILS, SET_PAYMENT_SEARCH_DETAILS, SET_PREVIOUS_BOND, SET_PRINCIPAL_INFORMATION, SET_PRINCIPAL_TYPE_ID, SET_REFERENCE_DATE, SET_RIDER_INFORMATION,
  SET_RIDER_TYPE_ID, SET_SIGNATORY_INFORMATION,
  SET_SIGNATORY_INFORMATION_ID, SET_STB_BOND_TYPE, SET_USER_TYPE, UPDATE_ADDITIONAL_NOTE, VIEW_RIDER_INFORMATION
} from './index';

/**
 * Used to store bond status.
 * @param payload Used to store payload.
 */
export function getBondStatusAction(payload) {
    return {
        type: FETCH_BOND_STATUS_SUCCESS,
        payload
    };
}

/**
 * Used to store bond details.
 * @param payload Used to store payload.
 */
export function setBondDetailsAction(payload) {
    return {
        type: FETCH_BOND_DETAILS_SUCCESS,
        payload
    };
}

/**
 * Used to reset bondDetails.
 * @param payload Used to store initail state of bondDetails.
 */
export function resetBondDetails(payload) {
    return {
        type: RESET_BOND_DETAILS,
        payload
    };
}

/**
 * Used to set client.
 * @param payload Used to store client for bond.
 */
export function setClientAction(payload: IClient) {
    return {
        type: SET_CLIENT,
        payload
    };
}
/**
 * Used to set client.
 * @param payload Used to store client for bond.
 */
export function setPaymentDetails(payload:any ) {
    return {
        type: SET_PAYMENT_DETAILS,
        payload
    };
}
/**
 * Used to set client.
 * @param payload Used to store client for bond.
 */
export function setPaymentSearchDetails(payload:any ) {
    
    return {
        type: SET_PAYMENT_SEARCH_DETAILS,
        payload
    };
}

/**
 * Used to update bond mode.
 * @param payload Used to store bond mode.
 */
export function setBondModeAction(payload: number) {
    return {
        type: SET_MODE,
        payload
    };
}

/**
 * Used to set bond type.
 * @param payload Used to store bond type.
 */
export function setBondTypeAction(payload: number) {
    return {
        type: SET_BOND_TYPE,
        payload
    };
}

/**
 * Used to set requestor.
 * @param payload Used to store payload.
 */
export function setRequestorListAction(payload: IRequestor[]) {
    return {
        type: FETCH_BOND_REQUESTOR_LIST,
        payload
    };
}

/**
 * Used to set country list.
 * @param payload Used to store payload.
 */
export function setCountryListAction(payload: ICountryList[]) {
    return {
        type: FETCH_COUNTRY_LIST_SUCCESS,
        payload
    };
}

/**
 * Used to set country list.
 * @param payload Used to store payload.
 */
export function setImporterNumberTyeListAction(payload: IImporterNumberType[]) {
    return {
        type: FETCH_IMPORTER_NUMBER_TYPE_LIST_SUCCESS,
        payload
    };
}

/**
 * Used to set country list.
 * @param payload Used to store payload.
 */
export function setActivityCodeListAction(payload: IActivityCode[]) {
    return {
        type: FETCH_ACTIVITY_TYPE_LIST_SUCCESS,
        payload
    };
}

/**
 * Used to set client search type list.
 * @param payload Used to store payload.
 */
export function setClientSearchTypeListAction(payload: IClientType[]) {
    return {
        type: FETCH_CLIENT_SEARCH_TYPE_LIST_SUCCESS,
        payload
    };
}

/**
 * Used to Store principal information.
 * @param payload Used to store principal data.
 */
export function setPrincipalInformationAction(payload) {
    return {
        type: SET_PRINCIPAL_INFORMATION,
        payload
    };
}

/**
 * Used to set previous bond information.
 * @param payload Used to store payload.
 */
export function setPrincipalTypeIdAction(payload) {
    return {
        type: SET_PRINCIPAL_TYPE_ID,
        payload
    };
}

/**
 * Used to delete used sub principal.
 * @param payload Used to store payload.
 */
export function deleteSubPrincipalAction(payload) {
    return {
        type: REMOVE_SUB_PRINCIPAL_INFORMATION,
        payload
    };
}

/**
 * Used to set bond information ssection values.
 * @param payload Used to store payload.
 */
export function setBondInformationAction(payload) {
    return {
        type: SET_BOND_INFORMATION,
        payload
    };
}

/**
 * Used to set previous bond information.
 * @param payload Used to store payload.
 */
export function setPreviousBondAction(payload) {
    return {
        type: SET_PREVIOUS_BOND,
        payload
    };
}

/**
 * Used to set stb bond information
 * @param payload Used to store payload
 */
export function setStbBondAction(payload) {
    return {
        type: SET_STB_BOND_TYPE,
        payload
    };
}

/**
 * Used to set stb bond information
 * @param payload Used to store payload
 */
export function setBondSubCategoryAction(payload: number) {
    return {
        type: SET_BOND_SUB_CATEGORY,
        payload
    };
}

/**
 * Used to set signatory information
 * @param payload Used to store payload
 */
export function SetSignatoryInformationAction(payload) {
    return {
        type: SET_SIGNATORY_INFORMATION,
        payload
    };
}

/**
 * Used to set signatory information id
 * @param payload Used to store payload
 */
export function SetSignatoryInformationIdAction(payload) {
    return {
        type: SET_SIGNATORY_INFORMATION_ID,
        payload
    };
}

/**
 * Used to set bond details from api
 * @param payload Used to store payload
 */
export function SetBondDetailsFromApi(payload) {
    return {
        type: SET_BOND_DETAILS_FROM_API,
        payload
    };
}

/**
 * Used to add additional notes.
 * @param payload Used to store note details
 */
export function fetchAditionalNotesAction(payload: IAdditionalNote[]) {
    return {
        type: FETCH_ADDITIONAL_NOTES,
        payload
    };
}

/**
 * Used to add additional note.
 * @param payload Used to store note details
 */
export function AddAditionalNoteAction(payload: IAdditionalNote) {
    return {
        type: ADD_ADDITIONAL_NOTE,
        payload
    };
}

/**
 * Used to delete additional note.
 * @param payload Used to store note details
 */
export function DeleteAditionalNoteAction(payload: number) {
    return {
        type: DELETE_ADDITIONAL_NOTE,
        payload
    };
}

/**
 * Used to update additional note.
 * @param payload Used to store note details
 */
export function UpdateAditionalNoteAction(payload: IAdditionalNote) {
    return {
        type: UPDATE_ADDITIONAL_NOTE,
        payload
    };
}

/**
 * Used to update additional note.
 * @param payload Used to store note details
 */
export function setNoteAsApprovalNoteAction(payload: boolean) {
    return {
        type: SET_NOTE_AS_APPROVAL_NOTE,
        payload
    };
}

/**
 * Used to set edit note id.
 * @param payload Used to store note details
 */
export function setEditNoteIdAction(payload: number) {
    return {
        type: EDIT_NOTE_ID,
        payload
    };
}

/**
 * Used to Store rider principal information.
 * @param payload Used to store rider principal data.
 */
export function setRiderInformationAction(payload) {
    return {
        type: SET_RIDER_INFORMATION,
        payload
    };
}

/**
 * Used to set.
 * @param payload Used to store payload.
 */
export function setRiderTypeIdAction(payload) {
    return {
        type: SET_RIDER_TYPE_ID,
        payload
    };
}

/**
 * Used to delete used sub principal.
 * @param payload Used to store payload.
 */
export function deleteRiderAction(payload: number) {
    return {
        type: REMOVE_RIDER_INFORMATION,
        payload
    };
}

/**
 * Used to clear rider information.
 * @param payload Used to store payload.
 */
export function clearRiderAction(payload: any[]) {
    return {
        type: CLEAR_RIDER_INFORMATION,
        payload
    };
}

/**
 * Used to view rider information.
 * @param payload Used to store payload.
 */
export function viewRiderAction(payload: IPrincipalInformation[]) {
    return {
        type: VIEW_RIDER_INFORMATION,
        payload
    };
}


/* Used to set principal information for paper bond.
 * @param payload Used to store payload.
 */
export function setPaperBondPrincipalAction(payload: IPrincipalInformation) {
    return {
        type: SET_PAPER_BOND_PRINCIPAL,
        payload
    };
}

/**
 * Used to set bond additional notes for bond.
 * @param payload Used to store payload.
 */
export function setBondAdditionalNotesAction(payload: IAdditionalNote[]) {
    return {
        type: SET_BOND_ADDITIONAL_NOTES,
        payload
    };
}


/**
 * Used to set User Type.
 * @param payload payload Used to store User Type.
 */
export function setUserTypeAction(payload: string) {
    return {
        type: SET_USER_TYPE,
        payload
    };
}

/**
 * Used to set change bond amount request.
 * @param payload payload Used to store User Type.
 */
export function setChangeBondAmountRequestAction(payload: IChangeBondAmount) {
    return {
        type: SET_CHANGE_BOND_AMOUNT_REQUEST,
        payload
    };
}

/**
 * Used to mergeRidersWithPrincipals.
 * @param payload payload Used to store User Type.
 */
export function mergeRidersWithPrincipals(payload: IPrincipalInformation[]) {
    return {
        type: MERGE_RIDER_WITH_PRINCIPALS,
        payload
    };
}


/**
 * Used to set paper bonds bond information..
 * @param payload payload Used to store User Type.
 */
export function setPaerBondsBondInfo(payload: IPaperBondBondInfo) {
    return {
        type: SET_PAPER_BOND_BOND_INFO,
        payload
    };
}

/**
 * Used to store bond status.
 * @param payload Used to store payload.
 */
export function getBondLogsAction(payload) {
    return {
        type: FETCH_BOND_LOGS,
        payload
    };
}

/**
 * Used to store bond status.
 * @param payload Used to store payload.
 */
export function setRefrenceDate(payload: string) {
    return {
        type: SET_REFERENCE_DATE,
        payload
    };
}

/**
 * Used to store bond category list.
 * @param payload Used to store payload.
 */
export function setBondCategoryList(payload) {
    return {
        type: SET_BOND_CATEGORY_LIST,
        payload
    };
}

/**
 * Used to set paper bonds bond information..
 * @param payload payload Used to store User Type.
 */
 export function setSuretyBondInfo(payload: IPaperBondSuretyInfo) {
    return {
        type: SET_PAPER_BOND_SURETY_INFO,
        payload
    };
}
