import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToasterService } from 'src/bis/shared/services/errorhandler/toaster.service';

@Component({
    selector: 'bis-toast',
    templateUrl: './toast.component.html'
})
export class ToastComponent implements OnInit {

    constructor(
    private messageService: MessageService,
    private errorMessage: ToasterService) { }

    ngOnInit(): void {
        this.errorMessage.showError.subscribe(res => {
            this.messageService.add({
                key: res.key,
                sticky: res.sticky,
                severity: res.severity,
                summary: res.heading,
                detail: res.details,
                life: res.life
            });
        });

        this.errorMessage.clearError.subscribe(res=>{
            this.messageService.clear();
        });
    }
}
