import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  ControlValueAccessor, UntypedFormControl,
  UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators
} from '@angular/forms';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs/internal/Observable';
import { BaseControl } from '../../utility/BaseController';

@Component({
    selector: 'bis-select',
    templateUrl: './select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SelectComponent),
            multi: true
        }
    ],
    styleUrls: ['./select.component.scss']
})
export class SelectComponent extends BaseControl<any> implements OnInit, OnChanges, ControlValueAccessor {
  icon = faCircleNotch;
  /**
   * Used to show | hide loader while api is processing.
   */
  showLoader = false;

  /**
   * Used to store status of api failure.
   */
  IsErrorOccured = false;

  /**
   * Used to store observable of of api call.
   */
  @Input() asyncOption: Observable<any>;

  /**
   * Store label field.
   */
  @Input() labelField: string;

  /**
   * Store placeholder.
   */
  @Input() l10nPlaceholder: string;

  /**
   * Used to update event on complete
   */
  @Output() dataRecived = new EventEmitter<any>();

  /**
   * Used to store class
   */
  @Input() className: string;

  /**
   * Used to store whether control is required or not.
   */
  @Input() required: boolean;

  /**
   * Used to store options.
   */
  options: any[];

  constructor() {
      super(new UntypedFormGroup({
          select: new UntypedFormControl('')
      }), 'dorpdownError');
  }

  ngOnInit() {
      this.getOptionList();
  }

  /**
   * Used to add reqquired validator.
   * @param changes Used to simple changes.
   */
  ngOnChanges(changes: SimpleChanges) {
      if (changes.required?.currentValue) {
          this.form.get('select').setValidators([Validators.required]);
      } else {
          this.form.get('select').setValidators(null);
      }
  }

  /**
   * Used to get drop down list.
   */
  getOptionList() {
      if (this.IsErrorOccured) {
          this.IsErrorOccured = !this.IsErrorOccured;
      }
      this.showLoader = true;
      this.asyncOption.subscribe({
        next:res => {
            this.options = res;
            this.showLoader = false;
            this.dataRecived.emit(res);
        },
        error: error => {
            this.showLoader = false;
            this.IsErrorOccured = true;
            this.dataRecived.emit(error);
        }
      }          
      );
  }

  /**
   * Used to refresh options.
   */
  refresh() {
      this.getOptionList();
  }

  /**
   * used to return class names recived from parent component.
   */
  getClass() {
      return this.className;
  }
}
