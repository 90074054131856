import { Pipe, PipeTransform } from '@angular/core';
import * as numeral from 'numeral';

@Pipe({
    name: 'bisAmountWrapper'
})
export class AmountWrapperPipe implements PipeTransform {
    transform(value, wrapper: 'parenthesis' | 'brackets' = 'parenthesis'): any {
      value = numeral(value).value();
        if (value < 0) {
            switch (wrapper) {
                case 'parenthesis':
                    return `(${numeral(value).format('0,0.00')})`;
                case 'brackets':
                    return `[${numeral(value).format('0,0.00')}]`;
            }
        }
        return numeral(value).format('0,0.00');
    }
}
