import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { BusyCacheItem, BusyThing } from '../../common/utility/BusyThing';

/**
 * Busy service.
 */
@Injectable()
export class BusyService {

  /**
   * Currently active busy things.
   */
  // private variable starts with _.
  // tslint:disable-next-line:variable-name
  private _Current = new Map<BusyThing, BusyCacheItem>();

  /**
   * Listen for changes to a busy thing.
   *
   * @param busy Busy thing.
   */
  Listen(busy: BusyThing) {
      if (!busy) {
          return of(false);
      }

      const existing = this._Current.get(busy);

      if (existing) {
          return existing.onChange;
      }

      const subject = new BehaviorSubject(!!busy);
      const onChange = subject.asObservable();
      const done = () => {
          subject.next(false);
          subject.complete();
          this._Current.delete(busy);
      };

      if (busy instanceof Promise) {
          busy.then(done, done);
      } else if (busy instanceof Subscription) {
          busy.add(done);
      }

      if (!existing) {
          this._Current.set(busy, { done, onChange });
      }

      return onChange;
  }

  /**
   * Convert a busy thing into an observable.
   *
   * @param busy Busy thing.
   */
  ToObservable(busy: BusyThing) {
      const subject = new Subject();
      const done = () => {
          subject.next(false);
          subject.complete();
      };

      if (busy instanceof Promise) {
          busy.then(done, done);
      } else if (busy instanceof Subscription) {
          busy.add(done);
      } else {
          done();
      }

      return subject.asObservable();
  }

  /**
   * Complete all outstanding busy things.
   */
  CompleteAll() {
      this._Current.forEach(item => {
          item.done();
      });
  }
}
