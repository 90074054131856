import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { IColumn } from 'src/bis/shared/interfaces/column.interface';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { orderBy } from 'lodash';
import { ReportsUtility } from 'src/bis/modules/reports/services/reports-utility.service';
@Component({
    selector: 'bis-table-list',
    templateUrl: './table-list.component.html',
    styleUrls: ['./table-list.component.scss']
})
export class TableListComponent implements OnInit {

    /**
     * Used to store all table details.
     */
    @Input() tableDetails;


    /**
     * Used to store column list
     */
    columnList: IColumn[];

    /**
     * Used to store row list.
     */
    rowList;

    /**
     * Used to store icon.
     */
    icon = faSpinner;

    /**
    * Used to store current page number.
    */
    @Input() type;
    @Input() renameColumn;
    @Input() modifiedColumnDetails;

    constructor() { }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.renameColumn && changes.renameColumn.currentValue) {
            this.renameColumn = changes.renameColumn.currentValue;
        }
        if (changes.modifiedColumnDetails && changes.modifiedColumnDetails.currentValue.length) {
            this.modifiedColumnDetails = changes.modifiedColumnDetails.currentValue;
        }
        if (changes.tableDetails.currentValue.length) {
            this._init(changes.tableDetails.currentValue);
        }

    }

    /**
     * Used to initialize data.
     * @param list Store row list.
     */
    private _init(list: any[]) {
        if (list.length) {
            this.columnList = this.renameColumn ?
                ReportsUtility.renameColumnNames(
                    ReportsUtility.extractColoumnList(this.tableDetails[0]),
                    this.modifiedColumnDetails) :
                ReportsUtility.extractColoumnList(this.tableDetails[0]);
            this.assignColumnType(this.columnList);
            this.rowList = this.tableDetails.map(res => ({ ...res }));
        }
    }

    /**
     * Used to assign type of column.
     * @param columnList: Store column list.
     */
    assignColumnType(columnList: IColumn[]) {
        this.columnList = columnList.map((res, index) => {
            const column = res;
            if (res.field === 'openDate' ||
                res.field === 'sentToCBP' ||
                res.field === 'responseFromCBP' ||
                res.field === 'updateDate') {
                column.type = 'dateTime';
            } else if (
                res.field === 'effectiveDate' ||
                res.field === 'terminationDate' ||
                res.field === 'cancelledDate' ||
                res.field === 'originalInvoiceDate' ||
                res.field === 'bondEffectiveDate') {
                column.type = 'date';
            }
            else if (res.field === 'suretyReferenceNumber') {
                column.type = 'link';
            } else if (
                res.field === 'bondAmount' ||
                res.field === 'grossPremium' ||
                res.field === 'netPremium') {
                column.type = 'amount';
            }
            else {
                column.type = 'text';
            }
            if (this.type === 'newBondReport' &&
                (res.field === 'bondAmount' ||
                    res.field === 'grossPremium' ||
                    res.field === 'netPremium')) {
                column.type = 'number';
            }
            return column;
        });
    }

    /**
      * Used to handle column sort operations.
      * @param event Store column sort event
      */
    sortColumn(event: { order: number, field: string }) {
        const order = event.order === 1 ? 'asc' : 'desc';
        this.rowList = orderBy(this.rowList, [event.field], [order]);
    }


}
