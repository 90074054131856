<div *ngIf="isAuthorised">
    <bis-header></bis-header>
    <router-outlet></router-outlet>
    <bis-toast></bis-toast>
    <bis-loader></bis-loader>
</div>
<div *ngIf="wrongUserDetails">
    Logged in user details is not found/incorrect.
    <button type="button" (click)="logout()">Logout</button>
</div>
<ngx-spinner type="animation">
    <p style="font-size: 20px; color: white">{{ loadingText }}</p>
</ngx-spinner>
<ngx-snackbar
    [position]="'bottom-center'">
</ngx-snackbar>
<p-dialog
    header="Session Timeout"
    [(visible)]="display"
    [style]="{ width: '30vw' }"
    [baseZIndex]="10000"
    [blockScroll]="true"
    [closeOnEscape]="false"
    [closable]="false"
    [modal]="true"

>
    <div class="row">
        <div class="col-12">
            <span class="email-sent">
                Your session is about to expire. You will be automatically logged out in {{ timer }} seconds. Please click Continue to stay logged in or log out
            </span>
        </div>
    </div>
    <div class="row mt-3 float-right">
        <!-- <div class="col-12 text-center success-btn"> -->
        <button
            (click)="logout();"
            class="btn btn-secondary mr-4 success-btn"
        >
            Logout
        </button>
        <button
            (click)="resetTimer(); display = false"
            class="btn btn-primary mr-4 yes-btn"
        >
            Continue
        </button>
        <!-- </div> -->
    </div>
</p-dialog>
