import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnauthorizedPageComponent } from './common/component/unauthorized-page/unauthorized-page.component';
import { AuthGuard } from './shared/helper/auth.guard';


const routes: Routes = [
    {
        path: 'accounting',
        loadChildren: () => import('./modules/accounting-utility/accounting-utility.module').then(m => m.AccountingUtilityModule)
    },
    {
        path: 'administration',
        loadChildren: () =>
            import('./modules/administration/administration.module').then(
                m => m.AdministrationModule
            ),
        canActivate: [AuthGuard]
    },
    {
        path: 'bondmanagement',
        loadChildren: () => import('./modules/bond-management/bond-management.module').then(m => m.BondManagementModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'accounting',
        loadChildren: () => import('./modules/accounting-utility/accounting-utility.module').then(m => m.AccountingUtilityModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'external',
    //     component: ExternalLoginComponent,
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'abi',
        loadChildren: () => import('./modules/abi/abi.module').then(m => m.ABIModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'invoice',
        loadChildren: () => import('./modules/invoice/invoice.module').then(m => m.InvoiceModule),
        // canActivate: [AuthGuard]
    },
    {
        path: 'chbusers',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)
    },
    {
        path: 'unauthorized-page',
        component: UnauthorizedPageComponent
    },
    {
        path: 'asi',
        loadChildren: () => import('./modules/asi/asi.module').then(m => m.AsiModule),
        canActivate: [AuthGuard]
    }
    // {
    //     path: '',
    //     redirectTo: environment.homepage,
    //     pathMatch: 'full'
    // }
    // {
    //     path: '',
    //     redirectTo: environment.homepage,
    //     pathMatch: 'full'
    // }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class BisRoutingModule { }
