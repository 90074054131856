import { Injectable } from '@angular/core';
import { SnackbarService } from 'ngx-snackbar';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class ToasterService {
    constructor(
        private snackbarService: SnackbarService
    ) { }

    static DEFAULT_TIMEOUT = 7000;

    static TIMEOUT_10_SECONDS = 10000;
    static ERROR_TIMEOUT = 15000;


    static SNACKBAR_TIME = 14000;

    static success = {
        position: 'bottom-center',
        max: 3,
        background: '#00ae7d',
        color: '#fff',
        timeout: ToasterService.DEFAULT_TIMEOUT,
        actionText: 'close',
        actionTextColor: '#fff'
    };

    static successSnackbar = {
        position: 'bottom-center',
        max: 3,
        background: '#00ae7d',
        color: '#fff',
        timeout: ToasterService.SNACKBAR_TIME,
        actionText: 'close',
        actionTextColor: '#fff'
    };

    static error = {
        position: 'bottom-center',
        max: 3,
        background: ' #e51c23',
        color: '#fff',
        accent: '#fff',
        timeout: ToasterService.SNACKBAR_TIME,
        actionText: '',
        actionTextColor: '#fff'
    };

    public showError = new Subject<any>();
    public clearError = new Subject<any>();
    public confirmationDailog = new Subject<any>();
    public lifeTime = 7000;

    public success(heading: string, details: string, life = ToasterService.DEFAULT_TIMEOUT): void {
        this.showError.next({ key: 'toasteralert',
            sticky: false,
            severity: 'success',
            heading,
            details,
            life });
    }

    public successSticky(heading: string, details: string, life = ToasterService.TIMEOUT_10_SECONDS): void {
        this.showError.next({ key: 'toasteralert',
            sticky: true,
            severity: 'success',
            heading,
            details
        });
    }

    public warningSticky(heading: string, details: string, life = ToasterService.TIMEOUT_10_SECONDS): void {
        this.showError.next({ key: 'toasteralert',
            sticky: true,
            severity: 'warn',
            heading,
            details
        });
    }

    public error(heading: string, details: string, life =ToasterService.TIMEOUT_10_SECONDS): void {
        this.showError.next({ key: 'toasteralert',
            sticky: false,
            severity: 'error',
            heading,
            details,
            life });
    }

    public warning(heading: string, details: string, life = ToasterService.DEFAULT_TIMEOUT): void {
        this.showError.next({ key: 'toasteralert',
            sticky: false,
            severity: 'warn',
            heading,
            details,
            life });
    }

    public info(heading: string, details: string, life = ToasterService.DEFAULT_TIMEOUT): void {
        this.showError.next({ key: 'toasteralert',
            sticky: false,
            severity: 'info',
            heading,
            details,
            life });
    }

    public confirmation(heading: string, details: string) {
        this.confirmationDailog.next({});
    }
    /**
     * Used to show success snackbar.
     * @param msg Used to store target message.
     */
    snackbarSuccess(msg: string, details = ToasterService.success) {
        this.snackbarService.add({
            msg: `${msg}`,
            timeout: details.timeout,
            color: details.color,
            background: details.background,
            action: {
                color: details.actionTextColor,
                text: details.actionText
            },
        });
    }

    /**
     * Used to show success snackbar.
     * @param msg Used to store target message.
     */
     snackbarSuccessLong(msg: string, details = ToasterService.successSnackbar) {
        this.snackbarService.add({
            msg: `${msg}`,
            timeout: details.timeout,
            color: details.color,
            background: details.background,
            action: {
                color: details.actionTextColor,
                text: details.actionText
            },
        });
    }

    /**
     * Used to show success snackbar.
     * @param msg Used to store target message.
     */
    snackbarError(msg: string, details = ToasterService.error) {
        this.snackbarService.add({
            msg: `${msg}`,
            timeout: details.timeout,
            color: details.color,
            background: details.background,
            action: {
                color: details.actionTextColor,
                text: details.actionText
            },
        });
    }
    clear(){
        this.clearError.next(true);
    }

    clearSnackBar(){
        this.snackbarService.clear();
    }
}
