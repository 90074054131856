import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { LocalStorageName } from '../enum/role.enum';
import { AppConfigModel } from '../interfaces/app-config-model';
import { ToasterService } from '../services/errorhandler/toaster.service';
import { OidcAuthService } from '../services/oidc-auth/oidc-auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private oauthService: OAuthService,
        private oidcAuthService: OidcAuthService,
        private router: Router,
        private toaster: ToasterService
    ) {
    }
    authConfigModel = new AppConfigModel();
    localStorageName = LocalStorageName;

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | boolean {
            this.toaster.clear();
        if (this.oauthService.hasValidAccessToken()) {

            return true;
        }
        // if (this.oauthService.getAccessToken()) {
            this.oidcAuthService.logout();
        // }
        // } else { USE this else block if possible
        //     this.router.navigate(['/']);
        //     this.oauthService.logOut();
        //     console.log('does not have valid access token in routing');
        //     return false;

        // }
        // this.oidcAuthService.logout();
    }
}
