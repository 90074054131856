import { Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserIdleService } from 'angular-user-idle';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RoleIdEnum } from './shared/enum/role.enum';
import { AppConfigModel } from './shared/interfaces/app-config-model';
import { ToasterService } from './shared/services/errorhandler/toaster.service';
import { OidcAuthService } from './shared/services/oidc-auth/oidc-auth.service';
import { AuthConfigService } from './shared/services/preferences-config/auth-config.service';
import { SpinnerService } from './shared/services/spinner.service';
import { LoginConfigurationService } from './shared/services/user-config/login-configuration.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
export let browserRefresh = false;

@Component({
    selector: 'bis-root',
    templateUrl: './bis.component.html'
})

export class BisComponent implements OnInit, DoCheck, OnDestroy {
    title = 'BIS-frontend';
    wrongUserDetails: boolean;
    authConfigModel = new AppConfigModel();
    display: boolean = false;
    /**
     * Used to store subscription.
     */
    subscription: Subscription[] = [];

    /**
     * Used to store loadingText
     */
    loadingText: string;
    timer: number;
    /**
     * to store access token after login
     */
    private static accessToken;
    /**
     * to store id token after login
     */
    private static idToken;

    availableUserRoles = RoleIdEnum;
    constructor(
        private oidcService: OidcAuthService,
        private oauthService: OAuthService,
        private loginConfigurationService: LoginConfigurationService,
        private authConfigService: AuthConfigService,
        private spinner: SpinnerService,
        private ngxSpinner: NgxSpinnerService,
        private route: Router,
        private userIdle: UserIdleService,
        private toster: ToasterService
    ) {

        this.route.events
            .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
            .subscribe(event => {
                if (
                    event.id === 1 &&
                    event.url === event.urlAfterRedirects
                ) {

                    browserRefresh = true;
                }
            })
        this.oauthService.events.subscribe(e => {
            if (e.type == 'token_expires') {
                this.oauthService.silentRefresh()
                    .then(success => console.log('refreshed', success))
                    .catch(err => {
                        this.toster.warning('Could not validate session', 'Logging user out', 5000);
                        this.oidcService.logout();
                    });
            }

        });

        window.addEventListener('storage', (event) => {
            if (event.storageArea == sessionStorage) {
                let token = sessionStorage.getItem('access_token');
                if (token == undefined) { // you can update this as per your key
                    // DO LOGOUT FROM THIS TAB AS WELL
                    console.log('token is undefined logging out');
                    this.oidcService.logout();
                }
            }
            if (event.key == 'access_token' || event.key == 'id_token') {
                this.oidcService.logout();
            }
        }, false);

        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: '2c30beea-3698-4dc4-9a5e-633b410244b7',
                loggingLevelConsole: 2,//logs all error/warnings
                autoTrackPageVisitTime: true,// track all page visit and visit timing
                enablePerfMgr: true,//performance behaviour
                enableAutoRouteTracking: true,
                /* ...Other Configuration Options... */
            }
        });
        appInsights.loadAppInsights();
        appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

    }


    ngOnInit(): void {
        this.subscription.push(
            this.spinner.updateSpinner.subscribe(res => {
                if (res.loadingText) {
                    this.loadingText = res.loadingText;
                }
                if (res.state === 'show' && res.details) {
                    this.ngxSpinner.show(undefined, res.details);
                    return;
                }
                this.ngxSpinner.hide();
            })
        );
        const previousUrl = window.location.pathname + window.location.search;
        if (!localStorage.getItem('previous_url') && previousUrl !== '/') {
            localStorage.setItem('previous_url', previousUrl);
        }

        this.spinner.showSpinner('');
        this.oidcService.configureAndLogin().then(result => {
            if (result) {
                this.loginConfigurationService.getUserDetails().then(
                    success => {
                        this.spinner.hideSpinner();
                        BisComponent.accessToken = this.oauthService.getAccessToken();
                        BisComponent.idToken = this.oauthService.getIdToken();
                        const getPreviousUrl = localStorage.getItem('previous_url');
                        if (getPreviousUrl) {
                            this.route.navigateByUrl(getPreviousUrl);
                            localStorage.removeItem('previous_url');
                            return;
                        } else {
                            const roleId = this.authConfigService.loggedInUserDetails.roleId;
                            if (roleId == this.availableUserRoles.EPIC_ADMIN ||
                                roleId == this.availableUserRoles.EPIC_SUPER_ADMIN ||
                                roleId == this.availableUserRoles.MASTER_BROKER ||
                                roleId == this.availableUserRoles.MASTER_BROKERS_HQ ||
                                roleId == this.availableUserRoles.VIEW_ONLY ||
                                roleId == this.availableUserRoles.SURETY_SUPER_ADMIN ||
                                roleId == this.availableUserRoles.ABI || 
                                roleId == this.availableUserRoles.BROKER){
                                this.route.navigate(['/bondmanagement']);
                            }
                            if (roleId == this.availableUserRoles.ACCOUNTING_EPIC ||
                                roleId == this.availableUserRoles.ACCOUNTING_Client ||
                                roleId == this.availableUserRoles.MASTER_ACCOUNTING_ROLE) {
                                this.route.navigate(['/accounting']);
                            }
                        }
                    }
                ).catch(error => this.spinner.hideSpinner());
            }
            // TODO: will remove below code once homepage and external login tested successfully
            // else {
            // if (previousUrl == '/') {
            //     localStorage.setItem('previous_url', '/bondmanagement');
            // } else {
            //     localStorage.setItem('previous_url', previousUrl);
            // }
            // }
        });
        this.userIdle.startWatching();

        this.userIdle.onTimerStart().subscribe((time) => {
            this.timer = environment.timeout - time;
            this.showDialog();
        });

        this.userIdle.onTimeout().subscribe((val) => {
            this.logout();
        });

    }

    ngDoCheck() {
        /**
         * Access token and idtoken change from browser storage was not detected on firefox browser.
         * to fix this issue stored access/ idtoken in static values and checked after each change that value in storage is changed or not
         */
        if (window.navigator.userAgent.indexOf('Firefox') !== -1) {
            if (this.isAuthorised && (BisComponent.accessToken !== sessionStorage.getItem('access_token') || BisComponent.idToken !== sessionStorage.getItem('id_token'))) {
                this.oidcService.logout();
            }
        }
    }

    logout() {
        this.oidcService.logout();
        BisComponent.accessToken = '';
        BisComponent.idToken = '';
    }

    get isAuthorised(): boolean {
        if (this.loginConfigurationService.wrongUserDetails) {
            this.wrongUserDetails = true;
        }
        // return true;
        return this.oauthService.hasValidAccessToken() && this.authConfigService.userType && !!this.authConfigService.rolesAndPreferences;
    }

    /**
     * Used to display dialog
     */
    showDialog() {
        if (this.isAuthorised) {
            this.display = true;
        }
    }

    /**
     * used to reset timer
     */
    resetTimer() {
        this.userIdle.resetTimer();
    }

    ngOnDestroy() {
        BisComponent.accessToken = '';
        BisComponent.idToken = '';
    }

}
