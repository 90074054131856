import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { PrincipalInformationSectionTypes } from 'src/bis/modules/bond-management/v2/enum/princeipal-info-sectio-type.enum';
import { PrincipalTypeIdEnum } from 'src/bis/modules/bond-management/v2/enum/principal-type-id.enum';
import { IBondDetails } from 'src/bis/modules/bond-management/v2/interface/bond-details.interface';
import { IPaperBondBondInfo } from 'src/bis/modules/bond-management/v2/interface/paper-bond-bond-info.interface';
import { IPaperBondSuretyInfo } from 'src/bis/modules/bond-management/v2/interface/paper-bond-surety-info.interface';
import { IPrincipalInformation } from 'src/bis/modules/bond-management/v2/interface/principal-information.interface';
import { BondStatus } from 'src/bis/shared/enum/bond-status.enum';
import { setPrincipalInformationAction } from '../actions/bond-actions';
import { IBISState } from '../store';

/**
 * Used to provide utility methods for bond.
 */
@Injectable({ providedIn: 'root' })
export class BondReduxUtil {
    constructor(
        private ngRedux: NgRedux<IBISState>
    ) { }

    /**
     * Used to return inital bond details value.
     */
    static get InitialBondDetails(): IBondDetails {
        return {
            bondId: 0,
            bondCategoryId: 0,
            clientId: 0,
            encryptedClientId: '',
            encryptedBondRequesterId: '',
            activityCodeId: 0,
            usCustomsBondNumber: '',
            executionDate: '',
            effectiveDate: '',
            entryTransactionDate: '',
            bondAmount: '',
            premium: '',
            fee: '',
            isTermReplace: false,
            entryCodeId: 0,
            entryNumber: '',
            signatureId: 1,
            createdBy: 0,
            clientReferenceNumber: '',
            isFromDataBase: false,
            // isDraft: false,  TODO: Will remove once functionality is tested
            bondStatus: '' as BondStatus,
            documentId: 0,
            suretyReferenceNo: '',
            approvalNote: '',
            underWritingDtos: [],
            issueDate: '',
            manualFiling: false,
            isEntryMatched: false,
            principalDtos: [this.initialPrincipalInfo],
            previousBondDto: {},
            bondDesignationCodeId: 0,
            clientInformation: {
                accountNumber: '',
                clientId: 0,
                clientName: '',
                countryName: '',
                filerCode: '',
                physicalAddress: '',
                physicalAddressCity: '',
                physicalAddressZipCode: '',
                stateName: '',
                encryptedClientId: ''
            },
            bondStatusId: 0,
            bondAdditionalNotes: [],
            isNoteApproval: false,
            editedNoteId: 0,
            paperBondPrincipalInfo: this.initialPrincipalInfo,
            changeBondAmountResponseDto: {},
            encryptedBondId: '',
            paperBondBondInformation: this.initialPaperBondInfo,

            merchandiseDtos: [{
                bondPrincipalMerchandiseId: 0,
                countryId: 0,
                htsCode: 0,
                merchandise: '',
                bondId: 0
            }],
            primaryPrincipalActivityDto: {
                estimatedDuties: 0,
                estimatedValue: 0,
                yearsDuties: 0,
                yearsValue: 0
            },
            scacCode: '',
            bondPurpose: '',
            activityCodeReasonId: 0,
            isInvoiced: false,
            referredReason: '',
            paperBondSuretyInformation: this.initialPepaerBondSuretyInfo,
            bondType: ''
        };
    }

    /**
     * Used to return property to reset bond state.
     */
    static get resetBondState() {
        return {
            bondStatus: '',
            bondDetails: BondReduxUtil.InitialBondDetails,
            bondMode: 0,
            bondType: 0,
            bondRequestorList: []
        };
    }

    /**
     * Used to return initial state of principal information.
     */
    static get initialPrincipalInfo(): IPrincipalInformation {
        return {
            isModified: false,
            isPrincipalFromDatabase: false,
            legalStatusId: 0,
            manualAddress: null,
            onHold: null,
            onWatchlist: null,
            physicalCity: '',
            physicalCountry: '',
            physicalCountryId: 0,
            physicalPostalCode: '',
            physicalState: '',
            physicalStateId: 0,
            physicalStreet1: '',
            physicalStreet2: '',
            principalActivityCode: 0,
            principalActivityCodeId: 0,
            principalAdditionCategoryId: 0,
            // principalId: null,
            encryptedPrincipalId: '',
            principalName: '',
            principalNumber: '',
            principalNumberTypeId: 0,
            principalTypeId: 1,
            resultCode: 0,
            stateOfIncorporation: 0,
            isGiaOnFile: false,
            isCollateralOnFile: false,
            isAddressRight: true,
            divakadba: null,
            divakadbaName: null,
        };
    }

    /**
     * Used to return initial state of rider principal information.
     */
    static get initialRiderPrincipalInfo(): IPrincipalInformation {
        return {
            isModified: false,
            isPrincipalFromDatabase: false,
            legalStatusId: 0,
            manualAddress: null,
            onHold: null,
            onWatchlist: null,
            physicalCity: '',
            physicalCountry: '',
            physicalCountryId: 0,
            physicalPostalCode: '',
            physicalState: '',
            physicalStateId: 0,
            physicalStreet1: '',
            physicalStreet2: '',
            principalActivityCode: 0,
            principalActivityCodeId: 0,
            principalAdditionCategoryId: 0,
            // principalId: 0,
            encryptedPrincipalId: '',
            principalName: '',
            principalNumber: '',
            principalNumberTypeId: 0,
            principalTypeId: 3,
            resultCode: 0,
            stateOfIncorporation: 0,
            isGiaOnFile: false,
            isCollateralOnFile: false,
            isAddressRight: true,
            divakadba: null,
            divakadbaName: null,
        };
    }

    /**
     * Used to return initial state of paper bond's bond info.
     */
    static get initialPaperBondInfo(): IPaperBondBondInfo {
        return {
            bondAmount: 0,
            bondNumber: '',
            effectiveDate: '',
            entityType: {},
            fee: null,
            premium: null,
            suretyName: '',
            suretyId: 0,
            usdotNumber: '',
            usdotDateReceived: null,
            suretyState: null,
            scacCode: '',
            executionDate: '',
            suretyAddress: '',
            bondDesignationCodeId: 0,
            bondAmountInWords: '',
            typeOfOrganization:null
        };
    }

    /**
     * Used to push initial primary principal to store.
     * @param type Used to store type of principal.
     */
    pushInitialPrincipalToStore(type: PrincipalInformationSectionTypes) {
        let principalTypeId: number;
        switch (type) {
            case PrincipalInformationSectionTypes.root:
                principalTypeId = PrincipalTypeIdEnum.PrimaryPrincipalTypeId;
                break;
            case PrincipalInformationSectionTypes.coprincipal:
                principalTypeId = PrincipalTypeIdEnum.CoPrincipalTypeId;
                break;
            case PrincipalInformationSectionTypes.unincorporated:
                principalTypeId = PrincipalTypeIdEnum.UnincorportedPrincipalTypeId;
                break;
        }
        this.ngRedux.dispatch(setPrincipalInformationAction(
            {
                data: {
                    ...BondReduxUtil.initialPrincipalInfo,
                    isPrincipalFromDatabase: false,
                    principalTypeId
                }
            }
        ));
    }

    
    /**
     * Used to return initial state of paper bond's bond info.
     */
    static get initialPepaerBondSuretyInfo(): IPaperBondSuretyInfo {
        return {
            suretyName: '',
            suretyId: 0,
            suretyAddress: '',
            policy: '',
            insurerName:''
        };
    }
}
