import * as ABILabel from '../../../common/lang/abi.json';
import * as Accountinglabel from '../../../common/lang/accounting.json';
import * as Administrationlabel from '../../../common/lang/administration.json';
import * as Bondlabel from '../../../common/lang/bond.json';
import * as CommodityLabel from '../../../common/lang/commodity.json';
import * as Commonlabel from '../../../common/lang/common.json';
import * as InvoiceLabel from '../../../common/lang/invoice.json';
import * as Reportslabel from '../../../common/lang/reports.json';
import * as UsersLabel from '../../../common/lang/users.json';
import * as AsiLabel from '../../../common/lang/asi.json';
import { Injectable } from "@angular/core";

@Injectable()
export class L10nService {

    /**
     * Used to store default language
     */
    defaultLanguage = 'en-US';

    /**
     * Used to store common labels.
     */
    CommonLabelDetails = (Commonlabel as any).default;

    /**
     * Used to store Accounting labels.
     */
    AccountingLabelDetails = (Accountinglabel as any).default;

    /**
     * Used to store administration labels.
     */
    AdministrationLabelDetails = (Administrationlabel as any).default;

    /**
     * Used to store bond labels.
     */
    BondLabelDetails = (Bondlabel as any).default;

    /**
     * Used to store bond labels.
     */
    ReportsLabelDetails = (Reportslabel as any).default;

    /**
     * Used to store invlice labels.
     */
    InvoiceLabelDetails = (InvoiceLabel as any).default;

    /**
     * Used to store commodity labels.
     */
    CommodityLabelDetails = (CommodityLabel as any).default;

    /**
     * Used to store ABI labels.
     */
    ABILabelDetails = (ABILabel as any).default;

    /**
     * Used to store ABI labels.
     */
    UsersLabelDetails = (UsersLabel as any).default;

      /**
     * Used to store ABI labels.
     */
       AsiLabelDetails = (AsiLabel as any).default;
    /**
     * Used to store module name.
     */
    moduleName: string;

    /**
     * Used to return label based on key.
     * @param key Used to store key for label.
     */
    Label(key: string): string {
        if (!key) {
            return '';
        }
        const extractModule = key.split('.'),
            module = extractModule.length === 2 ? extractModule[0] : 'Common';

        key = extractModule.length === 2 ? extractModule[1] : extractModule[0];
        if (!this[`${module}LabelDetails`][this.defaultLanguage][key]) {
            return '';
        }

        return this[`${module}LabelDetails`][this.defaultLanguage][key];
    }
}
