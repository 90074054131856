import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import * as numeral from 'numeral';

@Directive({
    selector: '[bisDecimalAmount]'
})
export class DecimalAmountDirective {
    constructor(
        private el: ElementRef
    ) { }

    @Input() bisDecimalAmount: 'paid' | 'unpaid'| 'bondAmount' = 'unpaid';

    @HostListener('focusout')
    onFocusOut() {
        if (!numeral(this.el.nativeElement.value).value()) {
            this.el.nativeElement.value = '';
            return;
        }
        let initalValue = this.el.nativeElement.value;
        if (this.bisDecimalAmount.toLowerCase() === 'paid') {
            initalValue = -Math.abs(initalValue);
        }
        if (this.bisDecimalAmount.toLowerCase() === 'bondamount') {

            initalValue = Math.ceil(Number(initalValue.toString().split(',').join('')));
        }
        const originalAmount = numeral(initalValue).format('0,0.00');
        this.el.nativeElement.value = originalAmount;
    }
    @HostListener('input', ['$event']) onInputChange(event) {
        let initalValue = this.el.nativeElement.value;
        if (this.bisDecimalAmount.toLowerCase() === 'paid') {
            initalValue = -Math.abs(initalValue);
        }
        if (this.bisDecimalAmount.toLowerCase() === 'bondamount') {

            initalValue = Math.ceil(Number(initalValue.toString().split(',').join('')));
        }
        this.el.nativeElement.value = initalValue.replace(/[^0-9,.]*/g, '').substring(0, 14);
        if ( initalValue !== this.el.nativeElement.value) {
          event.stopPropagation();
          return;
        }
        // else {
        //     const originalAmount = numeral(initalValue).format('0,0.[000000000]');
        //     this.el.nativeElement.value = originalAmount + '';
        //}
        // if (this.el.nativeElement.value) {
        //     this.el.nativeElement.value = numeral(this.el.nativeElement.value).format('0,0');
        // }
    }
}
