export enum BondTypes {
    CTB_ID = 1,
    CTB = 'CTB',

    STB_ID = 2,
    STB = 'STB',

    OTI = 'OTI',
    OTI_ID = 3,

    ISF = 'ISF',
    ISF_ID = 4,

    DOT = 'DOT',
    DOT_ID = 5,

    CNT = 'CARNET',
    CNT_ID = 6,

    FMCSA_BMC= 'FMCSA/BMC',
    FMCSA_BMC_ID = 7,

    NVOCC = 'NVOCC',
    NVOCC_ID = 8,

    FMC = 'FMC',
    FMC_ID = 9,

    SDDC = 'SDDC',
    SDDC_ID = 10,

    COURT_COST_BOND = 'Court Cost Bond',
    COURT_COST_BOND_ID = 11,

    MSC_BOND = 'MISC Bond (Other)',
    MSC_BOND_ID = 12
}
