import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { isArray } from 'util';
// import { isArray } from 'util';

@Injectable()
export class GoogleGeocodeService {
    constructor(private http: HttpClient) { }

    /**
     * Used to search address by using google api's.
     * @param address Used to store address.
     */
    searchAddress(address: string) {
        console.log('Input address', address);
        // Tslint:disable-next-line: max-line-length
        address= encodeURIComponent(address);
        return this.http.get<{results: any[]}>(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCTJvQIN9taNCOLYrWDpxc7B9iqGsdRIO0`)
            .pipe(
                map(res => {
                    if (!res.results) {
                        return false;
                    }
                    if (Array.isArray(res.results)) {
                        return res.results.map(
                            addressDetails => {
                                addressDetails.address_components.map(addressComponentDetails => {
                                    const countryDetails = addressComponentDetails.types.find(typeDetails => typeDetails === 'country');

                                    if (countryDetails) {
                                        addressDetails.country = addressComponentDetails.long_name;
                                        addressDetails.countryCode = addressComponentDetails.short_name;
                                    }
                                    const stateDetails = addressComponentDetails.types.find(typeDetails => typeDetails === 'administrative_area_level_1');

                                    if (stateDetails) {
                                        addressDetails.state = addressComponentDetails.long_name;
                                    }
                                });

                                return addressDetails;
                            }
                        );
                    }
                })
            );
    }
}
