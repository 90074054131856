import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../loader/loader.service';
import { OidcAuthService } from '../../oidc-auth/oidc-auth.service';

@Injectable()
export class BisHttpInterceptor implements HttpInterceptor {
    private totalRequest = 0;

    private startTime;

    private endTime;

    constructor(
        private loaderService: LoaderService,
        private oidcAuthService: OidcAuthService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.totalRequest++;
        
        if (request.url.includes('v1/transactions')) {
            if (request.url.indexOf('https:') === -1) {
                // const token = localStorage.getItem('access_token');
                const token = sessionStorage.getItem('access_token');

                var apiKey = environment.apiKey;
                var apiSecretKey = environment.apiSecretKey;
const credentials = btoa(`${apiKey}:${apiSecretKey}`);
                if (token) {
                    request = request.clone({
                        url: environment.paymentUrl + request.url,
                        setHeaders: {
                            Authorization: `Basic ${credentials}`
                        },
                        headers: new HttpHeaders({
                            'Cache-Control': 'no-cache, no-store, must-revalidate',
                            'Pragma': 'no-cache'
                        })
                    });
                } else {
                    this.oidcAuthService.logout();
                }
            }
            if (isDevMode()) {
                this.startTime = performance.now();
            }
    
            return next.handle(request).pipe(catchError(this.handleError), finalize(() => {
                this.totalRequest--;
                if (this.totalRequest === 0) {
                    this.loaderService.hideLoader();
                }
    
                if (isDevMode()) {
                    this.endTime = performance.now();
                    const apiLog = {
                        name: request.url,
                        time: `${(this.endTime - this.startTime).toString()} ms`
                    };
    
                    this.loaderService.addLog(apiLog);
                }
            }));
        }
        if (!request.url.includes('Accounting') && !window.location.href.includes('v2')) {
            this.loaderService.showLoader();
        }
        if (request.url.indexOf('https:') === -1) {
            // const token = localStorage.getItem('access_token');
            const token = sessionStorage.getItem('access_token');

            if (token) {
                request = request.clone({
                    url: environment.baseUrl + request.url,
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    },
                    headers: new HttpHeaders({
                        'Cache-Control': 'no-cache, no-store, must-revalidate',
                        'Pragma': 'no-cache'
                    })
                });
            } else {
                this.oidcAuthService.logout();
            }
        }
        if (isDevMode()) {
            this.startTime = performance.now();
        }

        return next.handle(request).pipe(catchError(this.handleError), finalize(() => {
            this.totalRequest--;
            if (this.totalRequest === 0) {
                this.loaderService.hideLoader();
            }

            if (isDevMode()) {
                this.endTime = performance.now();
                const apiLog = {
                    name: request.url,
                    time: `${(this.endTime - this.startTime).toString()} ms`
                };

                this.loaderService.addLog(apiLog);
            }
        }));
    }

    handleError(error) {
        if (error.status === 401 || error.status === 403) {
            this.oidcAuthService.logout();
        }
        if (error instanceof HttpErrorResponse) {
            // Display toster
            return throwError(error);
        }
        // Error handler required
        return throwError(error);
    }
}
