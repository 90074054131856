import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[bisNoWhiteSpace]'
})
export class NoWhiteSpaceDirective {

    constructor(
        private el: ElementRef
    ) { }

    @HostListener('input', ['$event']) onInputChange(event) {
        this.el.nativeElement.value = this.el.nativeElement.value.trim();
    }
}
