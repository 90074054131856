import { Pipe, PipeTransform } from '@angular/core';
import { BusyThing } from '../../common/utility/BusyThing';
import { BusyService } from '../services/busy.service';

/**
 * Converts a busy thing to an observable.
 *
 * ```html
 * <p>{{myThing | busy | async}}</p>
 * ```
 */
@Pipe({
    name: 'busy'
})
export class BusyPipe implements PipeTransform {
    constructor(
    private busy: BusyService
    ) { }

    /**
   * Transform value.
   *
   * @param value Value to transform.
   */
    transform(value: BusyThing) {
        return this.busy.Listen(value);
    }
}
