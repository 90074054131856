import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Directive({
    selector: '[bisIsControlRequired]'
})
export class IsControlRequiredDirective implements OnInit, AfterViewInit {
    constructor(private el: ElementRef, private renderer: Renderer2) { }

    @Input() bisIsControlRequired: UntypedFormControl;

    @Input() label: string;

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        const isRequired = this.hasRequiredField(this.bisIsControlRequired);
        if (isRequired) {
            this.el.nativeElement.innerHTML += this.label + '<span class="red ml-1">*</span>';
        } else {
            this.el.nativeElement.innerHTML += this.label;
        }
    }

    /**
     * Used to return whether control is required or not.
     * @param abstractControl Store form control object
     */
    hasRequiredField(abstractControl: AbstractControl) {
        if (abstractControl.validator) {
            const validator = abstractControl.validator({}as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        const control = (abstractControl as UntypedFormGroup).controls;
        if (control) {
            for (const controlName in control) {
                if (control[controlName]) {
                    if (this.hasRequiredField(control[controlName])) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

}
