import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { LocalStorageName } from '../enum/role.enum';

export class AppConfigModel {
    /**
     * used to map external url
     */
    externalLoginUrl = 'external';
    /**
     * used to map internal url
     */
    internalLoginUrl = '';
    /**
     * used to store microsoft link to connect respective tenant
     */
    discoveryDocUrl: string = 'https://' + environment.tenantName +
        '.b2clogin.com/' + environment.tenantName + '.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=';

    /**
     * used to store microsoft link to connect respective tenant
     */
    authBaseUrl: string = 'https://' + environment.tenantName +
        '.b2clogin.com/' + environment.tenantName + '.onmicrosoft.com/oauth2/v2.0/token?p=';

    changePasswordUrl: string = '';
    /**
     * used to store tenant name based of url
     */
    signInSignUpPolicyName: string;

    /**
     * store configuration parameters
     */
    authConfig: AuthConfig;

    /**
     * enum contains local storage varible name
     */
    localStorageName = LocalStorageName;
    DiscoveryDocumentConfig: any;
    changePwdPolicy = environment.changePwdPolicy;
    constructor() {
        this.setAppConfigVariable();
    }

    /**
     * used to set configuration on application load
     */
    setAppConfigVariable() {

        const redirectUrl = window.location.origin;
        this.signInSignUpPolicyName = environment.externalTenant;
        // this.setSignInPolicyAndRedirectUrl();

        this.DiscoveryDocumentConfig = {
            url: this.discoveryDocUrl + this.signInSignUpPolicyName
        };

        this.authConfig = {
            redirectUri: redirectUrl,
            silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
            responseType: 'token id_token',
            issuer: 'https://' + environment.tenantName + '.b2clogin.com/' + environment.tenantId + '/v2.0/',
            strictDiscoveryDocumentValidation: false,
            tokenEndpoint: this.authBaseUrl + this.signInSignUpPolicyName,
            loginUrl: this.authBaseUrl + this.signInSignUpPolicyName,
            clientId: environment.clientId,
            scope: 'openid profile https://' + environment.tenantName + '.onmicrosoft.com/api/user_impersonation',
            skipIssuerCheck: true,
            clearHashAfterLogin: true,
            oidc: true,
            silentRefreshShowIFrame: false,
            useSilentRefresh: true,
            sessionChecksEnabled:true
        }

        this.changePasswordUrl = 'https://'+ environment.tenantName +'.b2clogin.com/'+ environment.tenantName+'.onmicrosoft.com/oauth2/v2.0/authorize?p='+environment.changePwdPolicy+'&client_id='+environment.clientId+'&nonce=defaultNonce&redirect_uri='+this.authConfig.redirectUri+'&scope=openid&response_type=id_token&prompt=login';
    }

    setLocalStorage() {
        if (this.DiscoveryDocumentConfig.url.indexOf(environment.forgotPolicyTenantName) === -1) {
            localStorage.removeItem(this.localStorageName.redirect_url);
        }
        // if (this.signInSignUpPolicyName === environment.externalTenant) {
        //     localStorage.setItem(this.localStorageName.redirect_url, 'ex');
        // } else if (this.signInSignUpPolicyName === environment.internalTenant) {
        //     localStorage.setItem(this.localStorageName.redirect_url, 'in');
        // }
    }

    // setSignInPolicyAndRedirectUrl() {
    //     if (localStorage.getItem(this.localStorageName.redirect_url) === 'fp') {
    //         this.signInSignUpPolicyName = environment.externalTenant;
    //         // return window.location.origin + '/external';
    //         return window.location.origin;

    //     }
    //     // else if (window.location.href.toLowerCase().indexOf(this.externalLoginUrl) !== -1) {
    //     //     this.signInSignUpPolicyName = environment.externalTenant;
    //     //     return window.location.origin;
    //     // }

    //     this.signInSignUpPolicyName = environment.externalTenant;
    //     return window.location.origin;
    // }
}
