import { Component, Input, ViewChild } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';

@Component({
    selector: 'bis-common-email',
    templateUrl: './email.component.html',
    providers: [ConfirmationService]
})
export class EmailComponent {
    constructor(
        private confirmationService: ConfirmationService
    ) { }

    /**
     * Used to store label.
     */
    confirmationLabel = '';

    /**
     * Used to store template of modal.
     */
    @ViewChild('cd') confirmDialog: ConfirmDialog;

    /**
     * Used to store email sent status.
     */
    IsEmailSent = false;

    /**
     * Used to store download label
     */
    @Input() label: string;

    /**
     * Used to store download label
     */
    @Input() details: any;

    /**
     * Store send email progress.
     */
    isEmailSendingInProggress = false;

    /**
     * Used to store icon
     */
    icon = faSpinner;

    /**
     * Used to store.
     */
    isEmailPartiallySend = false;

    /**
     * store email message.
     */
    emailMessage: string;

    /**
     * Used to download invoice.
     */
    onEmail() {
        this.isEmailSendingInProggress = true;
        // const url = this.invoiceType === InvoiceTypes.BOND ? 'bisemailinvoicebybatch' : undefined;
        // this.accountService.emailInvoiceByBatchNumber(this.details.batchNumber, url).subscribe(
        //     res => {
        //         this.isEmailSendingInProggress = false;
        //         if (res.IsSuccess) {
        //             if (res.Data !== true) {
        //                 this.isEmailPartiallySend = true;
        //                 this.emailMessage = res.ReturnMessage;
        //             }
        //             this.IsEmailSent = true;
        //         }else{
        //             this.isEmailPartiallySend = true;
        //             (res.ReturnMessage == null) ?
        //             this.emailMessage = 'Could not send Email, Something went wrong' :
        //             this.emailMessage = res.ReturnMessage
        //             this.IsEmailSent = true;
        //         }
        //     },
        //     error: error => {
        //         this.isEmailSendingInProggress = false;
        //     }
        // );
    }

    /**
     * Used to hide email sent modal.
     */
    hideEmailModal = () => this.IsEmailSent = !this.IsEmailSent;

    /**
     * Used to open confirmation dialog.
     */
    openConfirmationDailog() {
        if (!this.details) {
            this.onEmail();
            return;
        }

        if (!this.details.generatedCount && !this.details.generatedInvoices) {
            this.onEmail();
            return;
        } else {
            this.confirmationLabel = `This action will send ${this.details.generatedCount || this.details.generatedInvoices} email(s)`;
        }

        this.confirmationService.confirm({
            message: `Please click confirm to proceed.`,
            icon: 'pi pi-info-circle',
            accept: () => {
                this.onEmail();
            },
            reject: () => { }
        });
    }
}
