import { Component } from '@angular/core';

@Component({
    selector: 'bis-profile-loader',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileSkeletonComponent {

}

