import { Directive, ElementRef, HostListener, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[bisIsAllowToEdit]'
})
export class KeyPreventDirective implements OnChanges {

    @Input() bisIsAllowToEdit = false;

    constructor(
        private el: ElementRef,
        private render: Renderer2
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        this.render[this.bisIsAllowToEdit ? 'addClass' : 'removeClass'](this.el.nativeElement, 'disabled-control');
        if (this.bisIsAllowToEdit) {
            // const tabIndex = this.bisIsAllowToEdit ? -1 : 0;
            this.render.setAttribute(this.el.nativeElement, 'tabindex', '0');
            this.render.setAttribute(this.el.nativeElement, 'readonly', 'readonly');
        } else {
            this.render.removeAttribute(this.el.nativeElement, 'tabindex');
            this.render.removeAttribute(this.el.nativeElement, 'readonly');
        }
    }

    /**
     * used to get key down event
     * @param event contain the event of control
     */
    @HostListener('keydown', ['$event'])
    onKeyDown(event) {
        if (event.keyCode === 9) {
            return true;
        }
        if (this.bisIsAllowToEdit) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    // onKeyDown(event: any) {
    //     if (event.keyCode === this.KeyPrevent) {
    //         event.preventDefault();
    //     }
    // }
}
