import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[bisPattern]'
})
export class PatternDirective {
    constructor(
        private el: ElementRef
    ) { }

    /**
     * Used to set target regular expression.
     * By default it works as only digit validator.
     */
    @Input() bisPattern: RegExp = /[^0-9]*/g;

    /**
     * Used to format data on input event.
     * @param event Used to store input data.
     */
    @HostListener('input', ['$event']) onInputChange(event) {
        const initalValue = this.el.nativeElement.value;
        this.el.nativeElement.value = initalValue.replace(this.bisPattern, '');
        if ( initalValue !== this.el.nativeElement.value) {
          event.stopPropagation();
          return;
        }
    }
}
