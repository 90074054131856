import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Subject } from 'rxjs/internal/Subject';
import { map } from 'rxjs/operators';
import { HttpService } from '../http/providers/http.service';
import { Observable, Subject } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    clientId: number;

    /**
     * used to subscribe the pending notification api
     */
    subscribeNotification = new Subject<boolean>();

    constructor(
        private helperService: HttpService,
        private http: HttpClient
    ) { }

    /**
     * Used to get notification
     */
    getNotification() {
        return this.helperService.get('UserManagement/pendingnotification').pipe(map(
            (result: any) => {
                return result;
            })
        );
    }

    /**
     * Used to get alert msg
     */
  getAlert() {
        let options = {
          headers: new HttpHeaders(),
      };

        return this.http.get(`Alert/get-alert`,  { ...options, responseType: 'text' });
  }

  /**
    * Used to get alert message to display : Gouthami
    */
  getAlertMsgToDisplay(data: Date) {
    let params = new HttpParams();
    params = params.append('fromDate', data.toJSON());
    return this.helperService.get('Alert/get-alertMsg?' + params).pipe(map(
      (result: any) => {
        return result;
      })
    );
  }
  
 
}
