import { Injectable } from '@angular/core';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class SpinnerService {

    /**
     * Used to update spinner state.
     */
    updateSpinner: Subject<{state: 'show' | 'hide', details: Spinner, loadingText?: string}> = new Subject();

    /**
     * Used to show spinner.
     * @param details Used to store details of spinner.
     */
    showSpinner(loadingText= 'Loading..', details?: Spinner) {
        let defaultSpinnerDetails: Spinner;
        if (!details) {
            defaultSpinnerDetails = {
                type: 'ball-spin-fade',
                size: 'medium',
                bdColor: 'rgba(0, 0, 0, 0.8)',
                color: '#fff',
                fullScreen: true
            };
        }
        this.updateSpinner.next({
            state: 'show',
            details: defaultSpinnerDetails,
            loadingText
        });
    }

    /**
     * Used to hide spinner.
     */
    hideSpinner() {
        this.updateSpinner.next({
            state: 'hide',
            details: {}
        });
    }
}
