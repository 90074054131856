import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Renderer2 } from '@angular/core';
import { DomHandler } from 'primeng/dom';

@Directive({
    selector: '[bisTabIndex]'
})
export class TabIndexDirectives {
    constructor(
        private el: ElementRef,
        private res: Renderer2,
        @Inject(DOCUMENT) private _document: HTMLDocument
    ) { }



    // @HostListener('keydown', ['$event'])
    @HostListener("focus", ["$event"])
    @HostListener('keydown.tab', ['$event'])
    @HostListener('keydown.shift.tab', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        let counter = 0;
        if (event.type === 'focus') {
            // counter = this.getCouterOfMatchedElement(event, counter);
            counter = this.getCouterOfMatchedElement(event, counter, this.el);
            return;
        }
        if (event.key !== 'Tab') {
            return;
        }
        event.preventDefault();

        counter = this.getCouterOfMatchedElement(event, counter, this.el);
        // for (let i = 0; i < this._document.querySelectorAll('[bisTabIndex]').length; i++) {
        //     if (domElem[i] === this.el.nativeElement) {
        //         break;
        //     }
        //     counter = this.setcounter(event, counter);
        // }
        let domElem = this.filteredList();
        while (true) {
            if (counter === (domElem.length -1) && !event.shiftKey) {
                counter = -1;
            }
            let nextElem: HTMLElement = <HTMLElement>(domElem[this.setcounter(event, Math.abs(counter))]);
            if (!nextElem) {
                if (event.shiftKey) {
                    counter = domElem.length;
                    continue;
                    // this.setFocus(<HTMLElement>(domElem[domElem.length - 1]));
                } else {
                    this.setFocus(<HTMLElement>(domElem[0]));
                }
                break;
            }
            if (this.checkISControlDisbaled(nextElem)) {
                counter = this.setcounter(event, Math.abs(counter));
                continue;
            }
            this.setFocus(nextElem);
            break;
        }
    }

    checkISControlDisbaled(nextElem: HTMLElement) {
        if (nextElem.className.indexOf('disabled-control') >= 0 ||
            (nextElem.hasAttribute('disabled') || (nextElem.hasAttribute('ng-reflect-disabled') && nextElem.attributes['ng-reflect-disabled'].value === 'true'))
        ) {
            return true;
        }
        return false;
    }

    /**
     * Used to set counter based on shift key
     */
    setcounter(event: KeyboardEvent, counter: number) {
        return event.shiftKey ? (counter - 1) : (counter + 1);
    }

    /**
     * Used to set focus based on controls
     */
    setFocus(nextElem: HTMLElement) {
        let focusableElement = DomHandler.getFocusableElements(nextElem);
        if (focusableElement.length) {
            focusableElement[0].focus();
        } else {
            setTimeout(() => { nextElem.focus(); }, 0);
        }
    }

    filteredList() {
        let filteredList = this._document.querySelectorAll('[bisTabIndex]');
        return filteredList;
    }

    getCouterOfMatchedElement(event: KeyboardEvent, counter: number, el: ElementRef) {
        let domElem = this._document.querySelectorAll('[bisTabIndex]');
        for (let i = 0; i < this._document.querySelectorAll('[bisTabIndex]').length; i++) {
            if (domElem[i] === el.nativeElement) {
                break;
            }
            counter = this.setcounter(event, counter);
        }
        return counter;
    }
}
