export enum BondActionEnum {
    EDIT_BOND = 1,
    TERMINATE_BOND = 2,
    ROLLOVER_BOND = 3,
    REQUEST_BOND_TERMINATION = 4,
    USER_RIDER = 5,
    RECONCILIATION_RIDER = 6,
    OVERRIDE_PREMIUM = 7,
    RECONCILIATION_RIDER_US_VIRGIN_ISLAND_AND_CHINA_RIDER = 8,
    UPDATE_REFRESH_IMPORTER_DETAILS = 9,
    VOID_BOND = 10,
    BROKER_OF_RECORD_CHANGE = 11,
    OPEN_BOND = 12,
    FORCE_CLOSE = 13,
    REQUERY_BOND = 14,
    GENERATE_BOND_COPY = 15,
    FORCE_VOID = 16,
    USER_RIDER_REQUEST = 17,
    RECONCILLIATION_RIDER_US_VIRGIN_ISLAND_RIDER_AND_CHINA_RIDER_REQUEST = 18,
    REQUEST_BOND_VOID = 19,
    CHANGE_BOND_AMOUNT = 20,
    FORCED_ACTION_ON_RIDER = 21,
    UPDATE_COLLATERAL = 22,
    UPDATE_GIA = 23,
    ADD_NAME_CHANGE_RIDER = 32,
    ADD_ADDRESS_CHANGE_RIDER = 33,
    ADD_CHINA_RIDER = 34,
    DELETE_CHINA_RIDER = 36
}

export enum NotificationPreferenceEnum {
    RECEIVE_INVOICE = 1,
    TERMINATION_BOND_NOTIFICATION = 2,
    USER_ADDITION_NOTIFICATION = 3,
    FOR_BOND_SUFFICIENCY_ALERTS = 4,
    FOR_CTB_INVOICES = 5,
    FOR_ISF_INVOICES = 6,
    FOR_OTHER_POLICY_INVOICES = 7,
    FOR_STATEMENTS = 8,
    ALL = 9,
    CARGO_INSURANCE_INVOICES = 10,
    FOR_APPRAISAL_CTB = 11,
    FOR_STB_INVOICES = 12
}

export enum OperationPreferencesEnum {
    PUT_ON_HOLD = 1,
    PUT_ON_WATCHLIST = 2,
    REMOVE_HOLD = 3,
    ADD_EDIT_USER_REQUEST = 4,
    USER_APPROVAL = 5,
    ADD_EDIT_CLIENT = 6,
    ADDEDIT_AUTHORITY_SCHEDULE = 7,
    ACTIVATE_DEACTIVATE_AUTHORITY_SCHEDULE = 8, // delete authority is also depends on this action
    ALL_DIRECT_CTB_FILING = 9,
    TYPE_OF_USER_REQUEST = 10,
    REMOVE_FROM_WATCHLIST = 11,
    ADD_EDIT_USER = 12,
    ADD_DELETE_APPLICATION_LEVEL_ALERTS = 13,
    VIEW_AUTHORITY_SCHEDULE = 14,
    EDIT_NOTES = 15,
    DELETE_NOTES = 16,
    CREATE_BOND = 17,
    VIEW_BOND = 18,
    EXPORT_BOND = 19,
    RESUBMISSION = 20
}

export enum ScreenPreferencesEnum {
    USER_MANAGEMENT = 1,
    HOLD_PRINCIPAL_MANAGEMENT = 2,
    CLIENT_MANAGEMENT = 3,
    AGENCY_MANAGEMENT = 4,
    APPROVE_USER = 5,
    MANAGE_AUTHORITY_SCHEDULE = 6,
    ROLE_AND_PERMISSIONS = 7,
    REFERRAL_AND_RULE_ALERTS = 8,
    CARGO_INVOICING = 9,
    BOND_INVOICING = 10,
    CASH_APPLICATION = 11,
    PAYMENT_TO_MARKET = 12,
    CANCEL_BOND = 13,
    GENERATE_PAYMENT_SUPPORT_DOCUMENT = 14,
    PRINT_INVOICES = 15,
    POLICY_INVOICING = 16,
    PAYMENT_TO_MARKET_UNCOLLECTED = 17,
    CREDIT_INVOICING = 18,
    UPLOAD_ISF_REPORT = 19,
    COMMODITY_MANAGEMENT = 20,
    ALERT_MANAGEMENT = 21,
    E_PAYMENT = 22
}

export enum ReportPreferencesEnum {
    VIEW_PREFERENCE = 1,
    EXPORT_PREFERENCES = 2
}

export enum AsiPreferencesEnum {
    EBOND_MANAGEMENT = 1,
    BULK_IMPORTER_QUERY = 2,
    ENTRY_SUMMARY = 3,
    AUTOMATED_ROLLOVER_TERMINATE = 4,
    PRINCIPAL_SUMMARY = 5
}