<div class="row">
    <div class="col-12 transaction-wrapper mt-4">
        <p-table [value]="rows">
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col" scope="col">
                        <div class="item">
                            <ngx-skeleton-loader
                              count="1"
                              [theme]="{ 'border-radius': '0', height: '20px', 'background-color': '#15364C'}"
                            ></ngx-skeleton-loader>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
                <tr>
                    <td *ngFor="let col of columns">
                        <div class="item">
                            <ngx-skeleton-loader
                                count="1"
                                [theme]="{ 'border-radius': '0', height: '20px', 'background-color': '#A9A9A9'}">
                            </ngx-skeleton-loader>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

