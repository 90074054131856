import { IClientType } from '../modules/administration/modules/client-management/interfaces/IClientType';
import { IImporterNumberType } from '../modules/bond-management/interfaces/IImporterNumberType';
import { IActivityCode } from '../modules/bond-management/v2/interface/activity-code.interface';
import { IBondDetails } from '../modules/bond-management/v2/interface/bond-details.interface';
import { IBondLogsList } from '../modules/bond-management/v2/interface/bond-logs-list.interface';
import { BondModes } from '../modules/bond-management/v2/interface/bond-mode.interface';
import { IBondNumberTableDetails } from '../modules/bond-management/v2/interface/bond-number-table-details.component';
import { IBondType } from '../modules/bond-management/v2/interface/bond-type.interface';
import { ICountryList } from '../modules/bond-management/v2/interface/country-list';
import { IPrincipalInformation } from '../modules/bond-management/v2/interface/principal-information.interface';
import { IRequestor } from '../modules/bond-management/v2/interface/requestor.interface';
import { ISignature } from '../modules/bond-management/v2/interface/signatures.interface';
import { ITypeOfStb } from '../modules/bond-management/v2/interface/stb-bond-type.interface';
import { RoleTypeEnum } from '../shared/enum/role.enum';
import { BondReduxUtil } from './util/bond-redux-util';

/**
 * Used to store structure of BIS store.
 */
export interface IBISState {
    bondStatus: string;
    bondDetails: IBondDetails;
    bondMode: BondModes;
    bondType: number;
    userType: RoleTypeEnum;
    bondRequestorList: IRequestor[];
    countryList: ICountryList[];
    activityCodeList: IActivityCode[];
    importerNumberTypeList: IImporterNumberType[];
    clientSearchTypeList: IClientType[];
    stbBondTypeList: ITypeOfStb[];
    bondSignatureList: ISignature[];
    bondDetailsFromApi: IBondDetails;
    bondRiderDetails: IPrincipalInformation[];
    bondLogs: IBondLogsList[];
    bondCategoryList: IBondType[];
    epaymentDetailsList:any;
    epaymentSearchDetailsList:any;
}

/**
 * Used to setup initail state.
 */
export const INITIAL_STATE: IBISState = {
    bondStatus: '',
    bondDetails: BondReduxUtil.InitialBondDetails,
    bondMode: 0,
    bondType: 0,
    userType: RoleTypeEnum.chb,
    bondRequestorList: [],
    countryList: [],
    activityCodeList: [],
    importerNumberTypeList: [],
    clientSearchTypeList: [],
    stbBondTypeList: [],
    bondSignatureList: [],
    bondDetailsFromApi: BondReduxUtil.InitialBondDetails,
    bondRiderDetails: [],
    bondLogs: [],
    bondCategoryList: [],
    epaymentDetailsList:[],
    epaymentSearchDetailsList:[]
};

