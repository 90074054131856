export enum PreferencesEnum {
    BOND_ACTION_PREFERENCES = 'bondActionPreferences',
    OPERATION_PREFERENCES = 'operationPreferences',
    NOTIFICATION_PREFERENCES = 'notificationPReferences',
    SCREENTILES_PREFERENCES = 'screenTilesPreferences',
    REPORT_PREFERENCES = 'reportPreferences',
    MENU_PREFERENCES = 'menuPreferences',
    ASI_PREFERENCES = 'asiPreferences',
}

export enum OperationCategoryPreferenceEnum {
    PRINCIPAL_ON_HOLD_AND_WATCHLIST = 1,
    USER_MANAGEMENT = 2,
    CLIENT_MANAGEMENT = 3,
    AUTHORITY_SCHEDULE = 4,
    ALERT_MANAGEMENT = 5,
    BOND_FILING = 6,
    BOND_MANAGEMENT = 7
}

export enum NotificationCategoryPreferenceEnum {
    BILLING_NOTIFICATION = 1,
    BOND_NOTIFICATION = 2,
    USER_MANAGEMENT_NOTIFICATION = 3,
    CLIENT_MANAGEMENT_NOTIFICATION = 4
}

export enum ScreenCategoryPreferenceEnum {
    ADMINISTRATION_TILES = 1,
    ACCOUNTING_TILES = 2,
    ASI_TILES =3
}

export enum ReportCategoryPreferencesEnum {
    IWATCH_REPORT = 1,
    BOND_ACTIVITY_REPORT = 3,
    ACCOUNT_USERS = 4,
    BOND_SUFFICIENCY_TOOLS = 5,
    OPEN_INVOICE_REPORT = 6,
    CANCELLATION_REPORT = 7,
    PRODUCTION_REPORT = 8,
    RECONCILIATION_REPORT = 9,
    PRODUCTION_OVERVIEW = 10,
    ANALYTICAL_ACTIVITY_REPORT = 11,
    STATEMENT_OF_ACCOUNT = 12,
    PRINT_INVOICE_REPORT = 13,
    PRODUCTION_ANALYSIS_REPORT = 14,
    SEARCH_REPORT_BY_CHECK_NUMBER = 15,
    ACCOUNT_USER_REPORT = 16,
    ADVANCED_PRORATED_REPORT = 17,
    VALID_CREDIT_REPORT = 18,
    ADD_CVD_REPORT = 19,
    NEW_BOND_REPORT = 20,
    SURETY_CANCELLATION_REPORT = 21,
    BOND_ADJUSTMENT_REPORT =22,
    OPEN_PAYABLE_REPORT = 24,
}

export enum MenuPreferencesEnum {
    IBOND = 1,
    INVOICE,
    REPORTS,
    ADMINISTRATION,
    ABI,
    USERS,
    ACCOUNTING,
    ASI
}