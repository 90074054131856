import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
    AsiPreferencesEnum,
    BondActionEnum,
    NotificationPreferenceEnum,
    OperationPreferencesEnum,
    ReportPreferencesEnum,
    ScreenPreferencesEnum
} from '../../enum/preferences-action.enum';
import {
    MenuPreferencesEnum,
    NotificationCategoryPreferenceEnum,
    OperationCategoryPreferenceEnum,
    PreferencesEnum,
    ReportCategoryPreferencesEnum,
    ScreenCategoryPreferenceEnum
} from '../../enum/preferences.enum';
import { LocalStorageName } from '../../enum/role.enum';
import { IRolesAndPreferences } from '../../interfaces/iroles-and-preferences';
import { IUserDetails } from '../../interfaces/iuser-details';

@Injectable({
    providedIn: 'root'
})
export class AuthConfigService {
    /**
     * Used to store status of questa web.
     */
    isQuestawebRunning = false;

    /**
     * store preferences name
     */
    preferencesEnum = PreferencesEnum;

    /**
     * used to access local storage item names
     */
    localStorageName = LocalStorageName;

    /**
     * contains type of user
     */
    userType: string;

    /**
     * store the localstorage preferences details
     */
    rolesAndPreferences: IRolesAndPreferences;

    /**
     * contains logged in user details
     */
    loggedInUserDetails: IUserDetails;

    rolesAndPreferenceLoaded = new Subject<boolean>();

    constructor(
        private http: HttpClient
    ) { }

    /**
     * Commom method to get preferences by preference Type
     * @param preferenceType Type of preference
     * @param preferenceCategoryId Pereference Category ID
     * @param preferenceId Preference ID
     */
    _getPreferences(preferenceType: string, preferenceCategoryId: number, preferenceId?: number) {

        if (!environment.enableRolesAndPreferences) {
            return true;
        }

        let preferencesDetails;
        switch (preferenceType) {
            case PreferencesEnum.BOND_ACTION_PREFERENCES:
                if (!this.rolesAndPreferences.bondActionPreferences || this.rolesAndPreferences.bondActionPreferences.length === 0) {
                    return false;
                }

                preferencesDetails = this.rolesAndPreferences.bondActionPreferences.find(
                    x => x.preferenceCategoryId === preferenceCategoryId && x.preferenceId === preferenceId
                );

                break;
            case PreferencesEnum.NOTIFICATION_PREFERENCES:
                if (!this.rolesAndPreferences.notificationPreferences || this.rolesAndPreferences.notificationPreferences.length === 0) {
                    return false;
                }

                preferencesDetails = this.rolesAndPreferences.notificationPreferences.find(
                    x => x.preferenceCategoryId === preferenceCategoryId && x.preferenceId === preferenceId
                );
                break;
            case PreferencesEnum.OPERATION_PREFERENCES:
                if (!this.rolesAndPreferences.operationPreferences || this.rolesAndPreferences.operationPreferences.length === 0) {
                    return false;
                }

                preferencesDetails = this.rolesAndPreferences.operationPreferences.find(
                    x => x.preferenceCategoryId === preferenceCategoryId && x.preferenceId === preferenceId
                );
                break;
            case PreferencesEnum.SCREENTILES_PREFERENCES:
                if (!this.rolesAndPreferences.screenTilePreferences || this.rolesAndPreferences.screenTilePreferences.length === 0) {
                    return false;
                }

                preferencesDetails = this.rolesAndPreferences.screenTilePreferences.find(
                    x => x.preferenceCategoryId === preferenceCategoryId && x.preferenceId === preferenceId
                );
                break;
            case PreferencesEnum.REPORT_PREFERENCES:
                if (!this.rolesAndPreferences.reportPreferences || this.rolesAndPreferences.reportPreferences.length === 0) {
                    return false;
                }

                preferencesDetails = this.rolesAndPreferences.reportPreferences.find(
                    x => x.preferenceId === preferenceCategoryId
                );

                if (!preferencesDetails) {
                    return false;
                }
                if (ReportPreferencesEnum.EXPORT_PREFERENCES === preferenceId) {
                    return preferencesDetails.exportPreference;
                } else if (ReportPreferencesEnum.VIEW_PREFERENCE === preferenceId) {
                    return preferencesDetails.viewPreference;
                }
                return false;
            case PreferencesEnum.MENU_PREFERENCES:
                if (!this.rolesAndPreferences.menuPreferences || this.rolesAndPreferences.menuPreferences.length === 0) {
                    return false;
                }
                preferencesDetails = this.rolesAndPreferences.menuPreferences.find(
                    x => x.preferenceId === preferenceCategoryId
                );

                break;
            case PreferencesEnum.ASI_PREFERENCES:
                if (!this.rolesAndPreferences.asiPreferences || this.rolesAndPreferences.asiPreferences.length === 0) {
                    return false;
                }

                preferencesDetails = this.rolesAndPreferences.asiPreferences.find(
                    x => x.preferenceId === preferenceId
                );
                break;
        }

        if (!preferencesDetails) {
            return false;
        }

        if (!!preferencesDetails.preferenceValue) {
            return true;
        }

        return false;
    }

    /**
     * Used to check if Action allowed on Operational level
     * @param preferenceCategoryId Contains preference category Id
     * @param preferenceId Contains preference Id
     */
    checkActionForOperational(preferenceCategoryId: OperationCategoryPreferenceEnum, preferenceId: OperationPreferencesEnum) {

        return this._getPreferences(PreferencesEnum.OPERATION_PREFERENCES, preferenceCategoryId, preferenceId);
    }

    /**
     * Used to check if Action allowed on Notification level
     * @param preferenceCategoryId Contains preference category Id
     * @param preferenceId Contains preference Id
     */
    checkActionForNotification(preferenceCategoryId: NotificationCategoryPreferenceEnum, preferenceId: NotificationPreferenceEnum) {

        return this._getPreferences(PreferencesEnum.NOTIFICATION_PREFERENCES, preferenceCategoryId, preferenceId);
    }

    /**
     * Used to check if Action allowed on Bond level
     * @param preferenceCategoryId Contains preference category Id
     * @param preferenceId Contains preference Id
     */
    checkActionForBond(preferenceCategoryId: number, preferenceId: BondActionEnum) {

        return this._getPreferences(PreferencesEnum.BOND_ACTION_PREFERENCES, preferenceCategoryId, preferenceId);
    }

    /**
     * Used to check if Action allowed on Bond level
     * @param preferenceCategoryId Contains preference category Id
     * @param preferenceId Contains preference Id
     */
    checkActionForScreenTiles(preferenceCategoryId: ScreenCategoryPreferenceEnum, preferenceId: ScreenPreferencesEnum) {

        return this._getPreferences(PreferencesEnum.SCREENTILES_PREFERENCES, preferenceCategoryId, preferenceId);
    }

    /**
     * Used to check if Action allowed on Report level
     * @param preferenceCategoryId Contains preference category Id
     * @param preferenceId Contains preference Id
     */
    checkActionForReport(preferenceCategoryId: ReportCategoryPreferencesEnum, preferenceId: ReportPreferencesEnum) {
        return this._getPreferences(PreferencesEnum.REPORT_PREFERENCES, preferenceCategoryId, preferenceId);
    }

    /**
     * used to get all preferences of category
     * @param preferences Contains preferences, preferencesEnum values has data
     * @param preferenceCategoryId Contains preference category Id
     */
    getAllActionForModule(preferences: PreferencesEnum, preferenceCategoryId: number) {
        if (!environment.enableRolesAndPreferences) {
            return [];
        }

        switch (preferences) {
            case PreferencesEnum.BOND_ACTION_PREFERENCES:
                if (!this.rolesAndPreferences.bondActionPreferences || this.rolesAndPreferences.bondActionPreferences.length === 0) {
                    return [];
                }

                return this.rolesAndPreferences.bondActionPreferences.find(
                    x => x.preferenceCategoryId === preferenceCategoryId
                );
            case PreferencesEnum.NOTIFICATION_PREFERENCES:
                if (!this.rolesAndPreferences.notificationPreferences || this.rolesAndPreferences.notificationPreferences.length === 0) {
                    return [];
                }

                return this.rolesAndPreferences.notificationPreferences.find(
                    x => x.preferenceCategoryId === preferenceCategoryId
                );
            case PreferencesEnum.OPERATION_PREFERENCES:
                if (!this.rolesAndPreferences.operationPreferences || this.rolesAndPreferences.operationPreferences.length === 0) {
                    return [];
                }

                return this.rolesAndPreferences.operationPreferences.find(
                    x => x.preferenceCategoryId === preferenceCategoryId
                );
            case PreferencesEnum.SCREENTILES_PREFERENCES:
                if (!this.rolesAndPreferences.screenTilePreferences || this.rolesAndPreferences.screenTilePreferences.length === 0) {
                    return [];
                }

                return this.rolesAndPreferences.screenTilePreferences.find(
                    x => x.preferenceCategoryId === preferenceCategoryId
                );
            case PreferencesEnum.REPORT_PREFERENCES:
                if (!this.rolesAndPreferences.reportPreferences || this.rolesAndPreferences.reportPreferences.length === 0) {
                    return [];
                }

                return this.rolesAndPreferences.reportPreferences;
            default: {
                return [];
            }
        }
    }

    /**
     * Used to validate user.
     * @param userType: Used to store user type.
     */
    validateUser(listOfusers = []) {
        return !!listOfusers.find(userType => this.loggedInUserDetails.roleId === userType);
    }

    /**
     * Used to get questa web status.
     */
    checkQuestaWebStatus() {
        const isQuestawebFlagAvailable = JSON.parse(sessionStorage.getItem('isQuestawebRunning'));
        if (isQuestawebFlagAvailable !== null && isQuestawebFlagAvailable !== undefined) {
            this.isQuestawebRunning = isQuestawebFlagAvailable;
            return;
        }
        this.http.get<boolean>('BondAction/checkisquestawebrunning').subscribe(
            res => {
                this.isQuestawebRunning = res;
                sessionStorage.setItem('isQuestawebRunning', JSON.stringify(res));
            }
        );
    }

    /**
 * Used to check if Action allowed on Bond level
 * @param preferenceId Contains preference Id
 */
    checkActionForMenu(preferenceId: MenuPreferencesEnum) {

        return this._getPreferences(PreferencesEnum.MENU_PREFERENCES, preferenceId);

    }

    /**
     * Used to check if Action allowed on Bond level
     * @param preferenceCategoryId Contains preference category Id
     * @param preferenceId Contains preference Id
     */
    checkActionForAsiScreenTiles(preferenceCategoryId: ScreenCategoryPreferenceEnum, preferenceId: AsiPreferencesEnum) {

        return this._getPreferences(PreferencesEnum.ASI_PREFERENCES, preferenceCategoryId, preferenceId);
    }
}
