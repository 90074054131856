<p-table [value]="rowList" (onSort)="sortColumn($event)" [rows]="100" scrollHeight="360px" [scrollable]="true" [virtualScroll]="true"
    [virtualRowHeight]="34" #searchTable>
    <ng-template pTemplate="header">
        <tr>
            <ng-container *ngFor="let col of columnList">
                <th *ngIf="!col.hidden" [pSortableColumn]="col.field" valign="top">
                    <div class="column-header" class="fixwidth">
                        {{ col.header }}
                        <p-sortIcon [field]="col.field" class="sort-icon-align"></p-sortIcon>
                    </div>
                </th>
            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
        <tr>
            <ng-container  *ngFor="let col of columnList" style="line-height: 15px;">
                <td *ngIf="!col.hidden" >
                    <div [ngSwitch]="col.type" class="fixwidth">
                        <ng-container *ngSwitchCase="'date'" >
                            {{row[col.field] | date: 'MM/dd/yyyy'}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'amount'" >
                            $ {{row[col.field] | bisAmountWrapper}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'number'" >
                            $ {{row[col.field] | number}}
                        </ng-container>
                        <ng-container *ngSwitchDefault >
                            {{row[col.field]}}
                        </ng-container>
                    </div>
                </td>
            </ng-container >
        </tr>
    </ng-template>
</p-table>