import { Directive, ElementRef, HostListener } from '@angular/core';


@Directive({
    selector: '[bisAllTextWithSlash]'
})
export class AllTextWithSlashDirective {
    private regex: RegExp = new RegExp('^[a-zA-Z0-9 _!#/^&.\'\\\\",:\-]+$');
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
    constructor(private el: ElementRef) {
    }
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        console.log('FreeTextDirective')
        if (
            (this.specialKeys.indexOf(event.key) !== -1) ||
            (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
            (event.keyCode === 86 && (event.ctrlKey || event.metaKey))) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        console.log('FreeTextDirective')
        //   if (
        //     (this.specialKeys.indexOf(event.key) !== -1) ) {
        //     return;
        // }
        let clipboardData = event.clipboardData;
        let pastedText = clipboardData.getData('text');
        if (pastedText.indexOf('\r\n') !== -1) {
            pastedText = pastedText.split('\r\n')[0];
        }
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(pastedText);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

}
