import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'bis-table-loader',
    templateUrl: './table.component.html'
})
export class TableSkeletonComponent implements OnChanges {
    /**
     * Used to store no row count.
     */
    @Input() rowCount;

    /**
     * Used to store no row count.
     */
    @Input() columnCount;

    /**
     * Used to store rows
     */
    rows = Array.from({length: 4}, () => Math.floor(Math.random() * 100));


    /**
     * Used to store no of coulmns.
     */
    columns = Array.from({length: 6}, () => Math.floor(Math.random() * 100));

    ngOnChanges(changes: SimpleChanges) {
        if (changes.rowCount.currentValue) {
            this.rowCount = changes.rowCount.currentValue;
            this.rows = Array.from({length: this.rowCount}, () => Math.floor(Math.random() * this.rowCount));
        }

        if (changes.columnCount.currentValue) {
            this.columnCount = changes.columnCount.currentValue;
            this.columns = Array.from({length: this.columnCount}, () => Math.floor(Math.random() * this.columnCount));
        }
    }
}
