import { Component, OnInit } from '@angular/core';
import { IAlertMessageGet } from '../../modules/administration/modules/alert-management/interfaces/ialert-message-get';
import { NotificationService } from '../../shared/services/notification/notification.service';


@Component({
    selector: 'bis-scrolling-text',
    templateUrl: './scrolling-text.component.html',
    styleUrls: ['./scrolling-text.component.scss'],
})
export class ScrollingTextComponent implements OnInit {
    isShow: boolean = false;
    alertMsg: any;
    alertmessage: IAlertMessageGet
    date: Date;
    toDate: Date;
    startDate: Date;
    currentDate: any;
    constructor(
        private helperService: NotificationService,
    ) {
    }

    ngOnInit(): void {
        this.getAlertMsg();
    }
    /**
     * Used to get alert msg
     */
    getAlertMsg() {
        this.date = new Date();
        this.currentDate = this.date.toJSON();
        this.helperService.getAlertMsgToDisplay(this.date).subscribe(
            {
                next: (result: any) => {
                    if (result) {
                        this.startDate = result.start_date;
                        this.toDate = result.end_date;
                        if (this.currentDate <= this.toDate) {
                            this.isShow = true;
                        }
                        else {
                            this.isShow = false;
                        }
                        this.alertMsg = result.alert_message;
                    } else {
                        this.alertMsg = '';
                    }
                }, error: err => {
                    this.alertMsg = '';
                }
            });
    }
}
