// Actions for bond status.
export const FETCH_BOND_STATUS = 'FETCH_BOND_STATUS';
export const FETCH_BOND_STATUS_ERROR = 'FETCH_BOND_STATUS_ERROR';
export const FETCH_BOND_STATUS_SUCCESS = 'FETCH_BOND_STATUS_SUCCESS';

// Actions for bond details.
export const FETCH_BOND_DETAILS = 'FETCH_BOND_DETAILS';
export const FETCH_BOND_DETAILS_ERROR = 'FETCH_BOND_DETAILS_ERROR';
export const FETCH_BOND_DETAILS_SUCCESS = 'FETCH_BOND_DETAILS_SUCCESS';
export const RESET_BOND_DETAILS = 'RESET_BOND_DETAILS';

// Action to set client.
export const SET_CLIENT = 'SET_CLIENT';

export const SET_MODE = 'SET_MODE';

export const SET_BOND_TYPE = 'SET_BOND_TYPE';

export const FETCH_BOND_REQUESTOR_LIST = 'FETCH_BOND_REQUESTOR_LIST';

export const FETCH_COUNTRY_LIST_SUCCESS = 'FETCH_COUFETCH_COUNTRY_LIST_SUCCESSNTRY_LIST';

export const FETCH_IMPORTER_NUMBER_TYPE_LIST_SUCCESS = 'FETCH_IMPORTER_NUMBER_TYPE_LIST_SUCCESS';

export const FETCH_ACTIVITY_TYPE_LIST_SUCCESS = 'FETCH_ACTIVITY_TYPE_LIST_SUCCESS';

export const FETCH_CLIENT_SEARCH_TYPE_LIST_SUCCESS = 'FETCH_CLIENT_SEARCH_TYPE_LIST_SUCCESS';

export const SET_PRINCIPAL_INFORMATION = 'SET_PRINCIPAL_INFORMATION';
export const REMOVE_SUB_PRINCIPAL_INFORMATION = 'REMOVE_SUB_PRINCIPAL_INFORMATION';
export const SET_PRINCIPAL_TYPE_ID = 'SET_PRINCIPAL_TYPE_ID';

export const SET_BOND_INFORMATION = 'SET_BOND_INFORMATION';

export const SET_PREVIOUS_BOND = 'SET_PREVIOUS_BOND';

export const SET_STB_BOND_TYPE = 'SET_STB_BOND_TYPE';

export const SET_BOND_SUB_CATEGORY = 'SET_BOND_SUB_CATEGORY';

export const SET_SIGNATORY_INFORMATION = 'SET_SIGNATORY_INFORMATION';

export const SET_SIGNATORY_INFORMATION_ID = 'SET_SIGNATORY_INFORMATION_ID';

export const SET_BOND_DETAILS_FROM_API = 'SET_BOND_DETAILS_FROM_API';

export const CLEAR_BOND_DETAILS = 'CLEAR_BOND_DETAILS';

export const SET_REFERENCE_DATE = 'SET_REFERENCE_DATE';

// action for notes.
export const ADD_ADDITIONAL_NOTE = 'ADD_ADDITIONAL_NOTE';
export const DELETE_ADDITIONAL_NOTE = 'DELETE_ADDITIONAL_NOTE';
export const UPDATE_ADDITIONAL_NOTE = 'UPDATE_ADDITIONAL_NOTE';
export const FETCH_ADDITIONAL_NOTES =  'FETCH_ADDITIONAL_NOTES';
export const SET_NOTE_AS_APPROVAL_NOTE = 'SET_NOTE_AS_APPROVAL_NOTE';
export const EDIT_NOTE_ID = 'EDIT_NOTE';

export const SET_RIDER_INFORMATION = 'SET_RIDER_INFORMATION';
export const SET_RIDER_TYPE_ID = 'SET_RIDER_TYPE_ID';
export const REMOVE_RIDER_INFORMATION = 'REMOVE_RIDER_INFORMATION';
export const CLEAR_RIDER_INFORMATION = 'CLEAR_RIDER_INFORMATION';
export const VIEW_RIDER_INFORMATION = 'VIEW_RIDER_INFORMATION';
export const MERGE_RIDER_WITH_PRINCIPALS = 'MERGE_RIDER_WITH_PRINCIPALS';
export const SET_PAPER_BOND_PRINCIPAL = 'SET_PAPER_BOND_PRINCIPAL';
export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS';
export const SET_PAYMENT_SEARCH_DETAILS = 'SET_PAYMENT_SEARCH_DETAILS';
export const SET_BOND_ADDITIONAL_NOTES = 'SET_BOND_ADDITIONAL_NOTES';
export const SET_USER_TYPE = 'SET_USER_TYPE';

export const SET_CHANGE_BOND_AMOUNT_REQUEST = 'SET_CHANGE_BOND_AMOUNT_REQUEST';
export const SET_PAPER_BOND_BOND_INFO = 'SET_PAPER_BOND_BOND_INFO';
export const FETCH_BOND_LOGS = 'FETCH_BOND_LOGS';
export const SET_BOND_CATEGORY_LIST = 'SET_BOND_CATEGORY_LIST';
export const SET_PAPER_BOND_SURETY_INFO = 'SET_PAPER_BOND_SURETY_INFO';
