import { Directive, ElementRef, Input } from "@angular/core";
import { DomHandler } from "primeng/dom";
@Directive({​​​​​​​​
    selector: "[bisAutofocus]"
}​​​​​​​​)
export class AutofocusDirective
{​​​​​​​​
    private focus = true;
    constructor(private el: ElementRef)
    {​​​​​​​​
    }​​​​​​​​

    ngOnInit()
    {​​​​​​​​
        if (this.focus)
        {​​​​​​​​
            //Otherwise Angular throws error: Expression has changed after it was checked.
            let focusableElement = DomHandler.getFocusableElements(this.el.nativeElement);
            if (focusableElement.length) {
                focusableElement[0].focus();
            } else {
                setTimeout(() => { this.el.nativeElement.focus(); }, 0);
            }
            // window.setTimeout(() =>
            // {​​​​​​​​
            //     this.el.nativeElement.focus(); //For SSR (server side rendering) this is not safe. Use: https://github.com/angular/angular/issues/15008#issuecomment-285141070)
            // }​​​​​​​​);
        }​​​​​​​​
    }​​​​​​​​

    @Input() set autofocus(condition: boolean)
    {​​​​​​​​
        this.focus = condition !== false;
    }​​​​​​​​
}​​​​​​​​

