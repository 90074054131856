import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'NegativeAmount'
})
export class NegativeAmountPipe implements PipeTransform {
    transform(value, state: 'Paid' | 'Unpaid') {
        if (!value) {
            return 0;
        }

        if (!state) {
            return value;
        }

        if (state === 'Paid') {
            return -Math.abs(value);
        }
        return value;
    }
}
