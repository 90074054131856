import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[bisToolTipErrorHandller]'
})
export class ToolTipErrorHandlerDirective {

    @Input() bisToolTipErrorHandller = false;

    constructor(
        private el: ElementRef,
        private render: Renderer2
    ) { }

    // ngOnChanges(changes: SimpleChanges): void {
    //     this.render[this.bisIsAllowToEdit ? 'addClass' : 'removeClass'](this.el.nativeElement, 'disabled-control');
    // }

    /**
     * used to get key down event
     * @param event contain the event of control
     */
    @HostListener('mouseenter', ['$event'])
    onMouseOver(event) {
        this.render.setAttribute(this.el.nativeElement, 'pTooltip', 'sample message');
        console.log('On mouse over', this.bisToolTipErrorHandller, this.el);
    }
}
