import { NgRedux, NgReduxModule } from '@angular-redux/store';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OAuthModule } from 'angular-oauth2-oidc';
import { UserIdleModule } from 'angular-user-idle';
import { SnackbarModule } from 'ngx-snackbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DialogModule } from 'primeng/dialog';
import { environment } from 'src/environments/environment';
import { BisRoutingModule } from './bis-routing.module';
import { BisComponent } from './bis.component';
import { CommonCoreModule } from './common/common-core.module';
import { rootReducer } from './redux/reducer/root-reducer';
import { IBISState, INITIAL_STATE } from './redux/store';
import { BisHttpInterceptor } from './shared/services/http/interceptor/bis-http-interceptor';
import { LoaderService } from './shared/services/loader/loader.service';

@NgModule({
    declarations: [
        BisComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
        FormsModule,
        BisRoutingModule,
        HttpClientModule,
        CommonCoreModule,
        BrowserAnimationsModule,
        DialogModule,
        OAuthModule.forRoot({
            resourceServer:
      {
          allowedUrls: [environment.baseUrl],
          sendAccessToken: true
      }
        }),
        FontAwesomeModule,
        NgReduxModule,
        NgxSpinnerModule,
        SnackbarModule.forRoot(),
        UserIdleModule.forRoot({idle: environment.idleTime, timeout: environment.timeout})
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        {
        provide: HTTP_INTERCEPTORS,
        useClass: BisHttpInterceptor,
        multi: true
    }, LoaderService
    ],
    bootstrap: [BisComponent]
})
export class BisModule {
    constructor(ngRedux: NgRedux<IBISState>) {
        ngRedux.configureStore(rootReducer, INITIAL_STATE, []);
      }
}
